import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const Adonis = () => {

    gaModalContent(
        "anatomy-atatue-info-adonis",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-adonis@2x.png")} alt="Adonis" />
                </div>
                <div className="width60">
                    <p className="explore-number">Bertel Thorvaldsen</p>
                    <h2>Adonis</h2>
                    <p>Adonis may be the most handsome man in Greek mythology, but he had a rather traumatic start to life. He was born to a woman called Myrrha, who had an incestuous relationship with her father. As a punishment, she was turned into a tree by the god Zeus. When the appointed time came, the bark of this tree burst and the infant Adonis entered the world. In the statue shown here, we see Adonis supporting himself on a tree with a dead hare, referencing his life and death. Although killed on a boar hunt while still a young man, he has been immortalised as a standard for desirable male aesthetics.</p>
                </div>
            </div>
        </>  
    );
};

export default Adonis;