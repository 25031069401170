import React, { useLayoutEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../../pages/Home/Home";
import ModuleSelection from "../../pages/ModuleSelection/ModuleSelection";
import AnatomyTraining from "../../pages/AnatomyTraining/AnatomyTraining";
// import AnatomyIntroduction from "../../pages/AnatomyIntroduction/AnatomyIntroduction";
import FullBodyAnatomy from "../../pages/AnatomyIntroduction/IntroFullBody/IntroFullBody";
import IntroTissues from "../../pages/AnatomyIntroduction/IntroTissues/IntroTissues";
import IntroEpithelialTissue from "../../pages/AnatomyIntroduction/IntroEpithelialTissue/IntroEpithelialTissue";
import IntroMuscleTissue from "../../pages/AnatomyIntroduction/IntroMuscleTissue/IntroMuscleTissue";
import IntroNervousTissue from "../../pages/AnatomyIntroduction/IntroNervousTissue/IntroNervousTissue";
import IntroConnectiveTissue from "../../pages/AnatomyIntroduction/IntroConnectiveTissue/IntroConnectiveTissue";
import IntroAdiposeTissue from "../../pages/AnatomyIntroduction/IntroAdiposeTissue/IntroAdiposeTissue";
import IntroFibrousTissue from "../../pages/AnatomyIntroduction/IntroFibrousTissue/IntroFibrousTissue";
import IntroCartilage from "../../pages/AnatomyIntroduction/IntroCartilage/IntroCartilage";
import IntroBlood from "../../pages/AnatomyIntroduction/IntroBlood/IntroBlood";
import IntroBone from "../../pages/AnatomyIntroduction/IntroBone/IntroBone";
import IntroAdiposeTissueInDetail from "../../pages/AnatomyIntroduction/IntroAdiposeTissueInDetail/IntroAdiposeTissueInDetail";
import IntroTypes from "../../pages/AnatomyIntroduction/IntroTypes/IntroTypes";
import IntroFatDistribution from "../../pages/AnatomyIntroduction/IntroFatDistribution/IntroFatDistribution";
import IntroFactorsAffectingFatDistribution from "../../pages/AnatomyIntroduction/IntroFactorsAffectingFatDistribution/IntroFactorsAffectingFatDistribution";





import Page404 from "../../pages/Page404/Page404";
import { useWinSize, useWinSizeUpdate } from "../../components/context/mixContext";


import "./Content.css";

const Content = () => {

  const winSize = useWinSize();
  const winSizeSet = useWinSizeUpdate();

  function useWindowSize() {
    // const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        winSizeSet([window.innerWidth, window.innerHeight]);
        
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return winSize;
  }
  useWindowSize()

  // function ShowWindowDimensions(props) {
  //   const [width, height] = useWindowSize();
  //   return <span>Window size: {width} x {height}</span>;
  // }



  
  return (
    <>
    
      <div className="main">
      {/* <svg height="0" width="0" xmlns="http://www.w3.org/2000/svg">
        <filter id="drop-shadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2.2"/>
          <feOffset dx="2" dy="2" result="offsetblur"/>
          <feFlood floodColor="rgba(0,0,0,0.3)"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </svg> */}
        <Routes>
          <Route exact path="/" element={< Home />} />
          <Route exact path="/module-selection" element={< ModuleSelection />} />
          <Route exact path="/anatomy-training" element={< AnatomyTraining />} />
          {/* <Route path="/anatomy-introduction" element={< AnatomyIntroduction />} /> */}

          <Route path="/anatomy-introduction/full-body-anatomy" element={<FullBodyAnatomy />} />
          <Route path="/anatomy-introduction/introduction-to-tissues" element={<IntroTissues />} />
          <Route path="/anatomy-introduction/epithelial-tissue" element={<IntroEpithelialTissue />} />
          <Route path="/anatomy-introduction/muscle-tissue" element={<IntroMuscleTissue />} />
          <Route path="/anatomy-introduction/nervous-tissue" element={<IntroNervousTissue />} />
          <Route path="/anatomy-introduction/connective-tissue" element={<IntroConnectiveTissue />} />
          <Route path="/anatomy-introduction/adipose-tissue" element={<IntroAdiposeTissue />} />
          <Route path="/anatomy-introduction/fibrous-tissue" element={<IntroFibrousTissue />} />
          <Route path="/anatomy-introduction/cartilage" element={<IntroCartilage />} />
          <Route path="/anatomy-introduction/bone" element={<IntroBone />} />
          <Route path="/anatomy-introduction/blood" element={<IntroBlood />} />
          <Route path="/anatomy-introduction/adipose-tissue-in-detail" element={<IntroAdiposeTissueInDetail />} />
          <Route path="/anatomy-introduction/types" element={<IntroTypes />} />
          <Route path="/anatomy-introduction/fat-distribution" element={<IntroFatDistribution />} />
          <Route path="/anatomy-introduction/factors-affecting-fat-distribution" element={<IntroFactorsAffectingFatDistribution />} />

          <Route path="*" element={< Page404 />} />
        </Routes>
      </div>
    </>
  );
};

export default Content;
