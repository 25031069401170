import React from 'react';
import Icon from '../../assets/images/gallery-icon.png';

import "./DownloadButton.css"

const DownloadButton = (props) => {

    return (
        <div className="download-button" onClick={()=>props.downloadHandle()}>
            <div className="download-icon">
                <svg id="Group_740" x="0px" y="0px" viewBox="0 0 18 24.9">
                <line id="Line_1" className="di0" x1="8.9" y1="17.4" x2="8.9" y2="0"/>
                <line id="Line_142" className="di0" x1="0.2" y1="22.9" x2="18" y2="22.9"/>
                <path id="_Color" className="di1" d="M2.1,9.5L0,11.6l8.9,8.9l8.9-8.9l-2.1-2.1l-6.8,6.8L2.1,9.5z"/>
                </svg>
            </div>
            
            <div className="pb-label">
                <p>{props.label}</p>
            </div>
        </div>
    );
};

export default DownloadButton;