import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gaAudioButton, gaSliderView } from '../../utils/gatags';
import { useLocation } from "react-router-dom";
import { useSlideIndexUpdate } from "../../components/context/mixContext";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


import sound1 from '../../assets/sound/abdomen.mp3';
import sound2 from '../../assets/sound/flanks.mp3';
import sound3 from '../../assets/sound/buttocks.mp3';
import sound4 from '../../assets/sound/thighs.mp3';
import sound5 from '../../assets/sound/upper_arms.mp3';
import sound6 from '../../assets/sound/submental.mp3';

import LeftMenu from "../../components/LeftMenu/LeftMenu";

// import required modules
import SwiperCore, { Parallax, FreeMode, Pagination, Navigation, Scrollbar } from "swiper";



// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./Gallery.css";

import GallerySlide from '../GallerySlide/GallerySlide';
import { useEffect } from 'react';
import JobCode from '../JobCode/JobCode';
SwiperCore.use([Scrollbar]);
const Gallery = () => {

    const location = useLocation();
    const audioElement = useRef(null);
    const audioArray = [sound1,sound2,sound3,sound4,sound5, sound6];
    const galleryArray = ["Abdomen","Flanks","Buttocks","Thighs","Arms","Submental"];

    const updSLIndx = useSlideIndexUpdate();

    const [slIndex, setSlIndex] = useState(0);
    const [slAudio, setSlAudio] = useState(audioArray[0]);
    const [audioActive, setAudioActive] = useState(false);
    const [biogActive, setBiogActive] = useState(false);

    useEffect(() => {
        const swipePagList = document.querySelectorAll(".swiper-pagination-bullet");
        if(swipePagList.length > 0) {
            swipePagList.forEach( (item,index) =>
                item.innerHTML = '<div class="lbl">Explore<br/>' + galleryArray[index] + '</div>'
            );
        }
      });
    const audioStyles = {
        width: '100%',
        marginTop: '10px'
    };
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    }
    const slInit = (_swiper) => {
        // setSlAudio(audioArray[0]);
        gaSliderView(
            'slider-view',
            'slider-scene-viewed',
            location.pathname + '-' + galleryArray[slIndex]
        )
    }
    const updateIndx = (_swiper) => {
        setSlIndex(_swiper.activeIndex);
        updSLIndx(_swiper.activeIndex);
        audioControls(false);
    }
    const slChange = (_swiper) => {
        gaSliderView(
            'slider-view',
            'slider-scene-viewed',
            location.pathname + '-' + galleryArray[slIndex]
        )
    }
    const audioControls = (_mode) => {
        setAudioActive(_mode);
        setSlAudio(audioArray[slIndex]);
        if (_mode) {
            audioElement.current.audio.current.currentTime = 0;
            // const playPromise = audioElement.current.audio.current.play(); 

            //ADD TO OTHER LANGUAGES =====
            gaAudioButton(
                'audio-play',
                'audio-button-clicked',
                galleryArray[slIndex]+ '-audio'
            )
            //===
            
            // if (playPromise !== null) {
            //     playPromise.catch(() => { /* discard runtime error */ })
            // }
        } else {
            audioElement.current.audio.current.pause();
            audioElement.current.audio.current.currentTime = 0;
            setBiogActive(false)
        }
    }
    
    const showBiog = () => {
        setBiogActive(!biogActive)
    }


    useEffect(() => {
    
    })

    return (
        
        <>
            <LeftMenu withFooter={true} activeBtns={[true,true,true,false,true,true,true]} logoutonly={false}/>
            <Swiper
                id="mySwiper"
                speed={1000}
                parallax={true}
                scrollbar={true}
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={1}
                freeMode={false}
                threshold = {10}
                preventClicks={false}
                // allowTouchMove={true}
                // preventClicksPropagation={true}
                grabCursor={true}
                pagination={pagination}
                navigation={true}
                modules={[Parallax, FreeMode, Pagination, Navigation, Scrollbar]}
                className="mySwiper"
                onAfterInit={(e) => slChange(e) }
                onSlideChange={(e) => updateIndx(e) }
                onSlideResetTransitionEnd={(e) => slChange(e) }
                onSlideChangeTransitionEnd={(e) => slChange(e) }
                onTouchEnd={() => slChange("TouchEnd") }
                // onSlideResetTransitionEnd={() => slInit() }
                // onSlideResetTransitionStart={() => slInit() }
                onTouchMove={() => slInit("TouchMove") }
                // onSwiper={(swiper) => console.log(swiper)}
                >
                <div id="parabg" className="parallax-bg">
                    {/* <div id="statue-light" className="light"></div> */}
                </div>
                {/* <div id="scaffold" className="scaffold" data-swiper-parallax="-500"></div> */}
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[0]} indx="0" audiofunc={audioControls} />
                </SwiperSlide>
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[1]} indx="1" audiofunc={audioControls} />
                </SwiperSlide>
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[2]} indx="2" audiofunc={audioControls} />
                </SwiperSlide>
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[3]} indx="3" audiofunc={audioControls} />
                </SwiperSlide>
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[4]} indx="4" audiofunc={audioControls} />
                </SwiperSlide>
                <SwiperSlide className="gallery-slide">
                    <GallerySlide title={galleryArray[5]} indx="5" audiofunc={audioControls} />
                </SwiperSlide>
            </Swiper>
  
            <JobCode />

            <div className={audioActive ? "audio-wrapper display-audio" : "audio-wrapper"}>
                <div className="audio-player">
                    <div className="global-close" onClick={() => audioControls(false)}></div>
                    <AudioPlayer id="audio-player" ref={audioElement} controls={true} src={slAudio} style={audioStyles} />
                    <div className="audio-biog-wrap">
                        <div onClick={() => showBiog()} className="biog-btn">
                            <div className="lbl">
                                <p>Jens Altmann, MD<br/><span><i>Plastic Surgeon</i></span></p>
                            </div>
                            <div className={biogActive ? "chev chev-rot" : "chev"}></div>
                        </div>
                        <div className={biogActive ? "biog-content biog-active" : "biog-content"}>
                            <div className="content-img">
                                <img src={require("../../assets/images/Dr_Altmann.png")} alt="Dr Altmann"/>
                            </div>
                            <div className="content-txt">
                                <p>Dr Altmann is a board-certified plastic surgeon and senior physician at the Bodenseeklinik in Lindau, Germany. He specialises in breast and face surgery, liposuction, operative body forming, and he has been a cyrolipolysis practitioner since 2016.</p>
                                <p>In addition to his role as General Secretary of the International Society for Aesthetic Medicine, he is also a member of the International Society of Aesthetic Plastic Surgery, the American Society of Plastic Surgeons, the German Society of Plastic, Reconstructive and Aesthetic Surgeons, and the German Society of Aesthetic-Plastic Surgeons.</p>
                                <p>Dr Altmann is one of the authors of publication: ‘Global Expert Opinion on Cryolipolysis Treatment Recommendations and Considerations: A Modified Delphi Study’.</p>
                            </div>
                            
                        </div>

                    </div>
                    

                </div>
                
            </div>
            


        </>
    );
};

export default Gallery;