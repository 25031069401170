import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const EnlargeSubmentalFemale = ({_muscle}) => {

    gaModalContent(
        "anatomy-atatue-info-submental-female",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div id="enlarge" className="img-holder">
                {_muscle ? 
                    <img src={require("../../../assets/images/popup-submental-female.png")} alt="Submental female" /> :
                    <img src={require("../../../assets/images/popup-submental-female-fat.png")} alt="Submental female" />
                }
            </div>
        </>    
    );
};

export default EnlargeSubmentalFemale;