import * as React from "react";
import "./EnlargeImgs.css";

    const SubmentalZoomLinks = (prop) => (

        <svg className="zoom-links" x="0px" y="0px"
                viewBox="0 0 1280 1552">
            <g id="lines">
                <path id="Path_936" className="st0" d="M903.1,905.9h31.8l141-178.3h32.2"/>
                <path id="Path_935" className="st0" d="M169.4,850.6h53l97.9,76.2h82.9"/>
                <path id="Path_935_00000025419024920520348450000001327923728153127088_" className="st0" d="M167.6,1008h182.2"/>
                <path id="Path_935_00000026153428571688714170000018169306085597485703_" className="st0" d="M970.1,1008h138"/>
            </g>
            <g id="hs2">
                <g id="Component_37_14_00000062883996945906751150000013546586467775270836_" transform="translate(526.459 151.729)">
                    <path id="Path_6-2_00000152246199693022918670000017579384578128639422_" className="st1" d="M649.4,571.7c0,18.7-15.2,33.9-33.9,33.9
                        s-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9S649.4,553,649.4,571.7L649.4,571.7z"/>
                    <path id="Path_7-2_00000178167696378296534220000009119116714287110041_" className="st1" d="M657.9,614.1l-18.4-18.4"/>
                    <path id="Path_8-2_00000013890969433064325450000018420276075100046742_" className="st1" d="M615.5,559v25.4"/>
                    <path id="Path_9-2_00000071534030606931728000000015489251281799472541_" className="st1" d="M602.8,571.7h25.4"/>
                </g>
                <rect onClick={() => prop.func(5,1,"popup")} x="1097" y="678.4" className="st2" width="90" height="90"/>
            </g>
            <g id="hs1">
                <g id="Component_37_13_00000118376901494002181600000015987741908838587785_" transform="translate(1.5 1.5)">
                    <path id="Path_6_00000138572241091368921460000001796210348905475217_" className="st1" d="M166.1,848.3c0,18.7-15.2,33.9-33.9,33.9
                        S98.3,867,98.3,848.3c0-18.7,15.2-33.9,33.9-33.9S166.1,829.5,166.1,848.3L166.1,848.3z"/>
                    <path id="Path_7_00000049200959239981335290000015229885326200011938_" className="st1" d="M174.6,890.7l-18.4-18.4"/>
                    <path id="Path_8_00000059277856161423084910000005252325722753974194_" className="st1" d="M132.2,835.6V861"/>
                    <path id="Path_9_00000147928874851771661070000011685656344865356958_" className="st1" d="M119.5,848.3h25.4"/>
                </g>
                <rect onClick={() => prop.func(5,0,"popup")} x="88.7" y="804.8" className="st2" width="90" height="90"/>
            </g>
            <g id="statuelink1">
                {/* <rect id="statue2-hover" x="816" y="1334" className="statue-hover abd2-hover fade-transition" width="285" height="105"/>
                <rect onClick={() => prop.func(5,1,"statue")} x="900.2" y="1301.3" className="audio-fill submental-statue" width="216.1" height="150"/> */}
                <rect x="900.2" y="1301.3" className="audio-fill submental-statue" width="216.1" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="560" y="1334" className="statue-hover abd1-hover fade-transition" width="255" height="105"/>
                <rect onClick={() => prop.func(5,0,"statue")} x="540" y="1301.3" className="audio-fill submental-statue" width="360.3" height="150"/> */}
                <rect x="540" y="1301.3" className="audio-fill submental-statue" width="360.3" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(5,0,"audio")} x="900.2" y="1137.5" className="audio-fill submental-statue" width="216.1" height="150"/> */}
            </g>


            <g id="info2">
                <g>
                    <path className="st4" d="M1175.9,1008c0,18.7-15.2,33.9-33.9,33.9c-18.7,0-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9
                        C1160.7,974.1,1175.9,989.3,1175.9,1008z M1146.9,999.1l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6
                        c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3s2.2,1.3,3.7,1.3
                        c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3
                        c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H1146.9z M1143.1,984.1c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
                        s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5s1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5
                        C1145.4,982.5,1144.2,983.1,1143.1,984.1z"/>
                        <rect onClick={() => prop.func(5,1,"statue")} x="1097" y="963" className="st3" width="90" height="90"/>
                </g>
            </g>
            <g id="info1">
                <g>
                    <path className="st4" d="M167.6,1008c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9S167.6,989.3,167.6,1008z
                        M138.7,999.1l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2
                        c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7
                        c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9
                        l7.4-25.6H138.7z M134.9,984.1c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5
                        c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5C137.1,982.5,135.9,983.1,134.9,984.1z"/>
                        <rect onClick={() => prop.func(5,0,"statue")} x="88.7" y="963" className="st3" width="90" height="90"/>
                </g>
            </g>

        </svg>

    );

export default SubmentalZoomLinks;