import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const VenusCallipyge = () => {

    gaModalContent(
        "anatomy-atatue-info-venus-callipyge",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-venus-callipyge@2x.png")} alt="Venus Callipyge" />
                </div>
                <div className="width60">
                    <p className="explore-number">Unknown artist</p>
                    <h2>Venus Callipyge</h2>
                    <p>‘The Venus Callipyge’ literally translates to Venus of the beautiful buttocks. It is an ancient Roman statue, thought to be a copy of an older Greek original. While the subject is conventionally identified as a goddess, it may equally be a portrait of a mortal woman. The marble statue shown here dates to the late first century BCE. However, the lost Greek original is thought to have been bronze, created around 300 BCE.</p>
                </div>
            </div>
        </>  
    );
};

export default VenusCallipyge;