import React, { useContext } from 'react';

const modalContext = React.createContext();
const modalUpdateContext = React.createContext();

const modalContentContext = React.createContext();
const modalContentUpdateContext = React.createContext();


export function useModal() {
   return useContext(modalContext);
}

export function useModalUpdate() {
    return useContext(modalUpdateContext)
}

export function useModalContent() {
    return useContext(modalContentContext);
 }
 
 export function useModalContentUpdate() {
     return useContext(modalContentUpdateContext);
 }


export function ModalProvider({ children }) {

    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [content,setContent] = React.useState('');

    function setModal(_vid) {
        const isOpen = !modalIsOpen
        setIsOpen(isOpen);
    }
    function setModalContent(_content) {
        setContent(_content);
        setIsOpen(true)
    }  
    
    return (
        <modalContentContext.Provider value={content}>
            <modalContentUpdateContext.Provider value={setModalContent}>
                <modalContext.Provider value={modalIsOpen}>
                    <modalUpdateContext.Provider value={setModal}>
                        {children}
                    </modalUpdateContext.Provider>
                </modalContext.Provider>
            </modalContentUpdateContext.Provider>
        </modalContentContext.Provider>
    )
}
