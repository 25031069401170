import React from 'react';

import "../Introduction-fragment.css";
import "./Bone.css"

const IntroductionTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">10</p>
            <h1>Bone<sup>1</sup></h1>
            <div className="fw-text">
                <p>Bone is the hard, calcified connective tissue that makes up the skeleton. The term ‘bone’ has two meanings in anatomy: an entire structure such as the femur, or just the osseous tissue. Whole bones have a number of structural components in addition to osseous tissue, such as cartilage, bone marrow and dense irregular connective tissue.</p>
            </div>
            <div className="width50">
                <h3>Compact bone</h3>
                <div className="into-img">
                    <img className="img-shadow" src={require("../../../../assets/images/intro-bone-fig1@2x.png")} alt="introduction to bone" />
                </div>
            </div>
        </div>
    );
};

export default IntroductionTissue;