import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useWinSize, useWinSizeUpdate } from "../../components/context/mixContext";
import { gaButtonClick } from '../../utils/gatags';

import './Submenu.css'

const Submenu = () => {

    const IntroWinSize = useWinSize();
    const IntroWinSizeSet = useWinSizeUpdate();
    const [menuState, setMenuState] = useState(true);
    const submenu = document.getElementById("submenu");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        var ul = document.getElementById("intro-menu");
        var items = ul.getElementsByTagName("li");
        for (var i = 0; i < items.length; ++i) {
            if (items[i].getAttribute("path") === location.pathname) {
                items[i].classList.add("submenu-active");
                // console.log(items[i].getAttribute("path"));
            }
        }
        
    },[]);

    
    const menuToggle = () => {
        setMenuState(!menuState);
        submenu.classList.toggle("show-submenu");
    }

    const menuClick = (e, _loc) => {
        gaButtonClick("full-body-anatomy-menu-click","click",_loc)
        navigate(_loc);
    }

    return (
        <div id="submenu" className={IntroWinSize[0] > 768 ? "submenu submenu-left show" : "submenu submenu-left hide"} >
            <h2 className="menu-head">Introduction to human anatomy</h2>
            <ul id="intro-menu" className="intro-menu">
                <li id="men0" className="ruler active" onClick={(e) => menuClick(e, "/anatomy-introduction/full-body-anatomy")} path="/anatomy-introduction/full-body-anatomy">Full body anatomy</li>
                <li id="men1" className="ruler" onClick={(e) => menuClick(e, "/anatomy-introduction/introduction-to-tissues")} path="/anatomy-introduction/introduction-to-tissues">Introduction to tissues</li>
                <li id="men2" onClick={(e) => menuClick(e, "/anatomy-introduction/epithelial-tissue")} path="/anatomy-introduction/epithelial-tissue">Epithelial tissue</li>
                <li id="men3" onClick={(e) => menuClick(e, "/anatomy-introduction/muscle-tissue")} path="/anatomy-introduction/muscle-tissue">Muscle tissue</li>
                <li id="men4" onClick={(e) => menuClick(e, "/anatomy-introduction/nervous-tissue")} path="/anatomy-introduction/nervous-tissue">Nervous tissue</li>   
                <li id="men5" onClick={(e) => menuClick(e, "/anatomy-introduction/connective-tissue")} path="/anatomy-introduction/connective-tissue">Connective tissue</li>
                    <li id="men6" className="menu-indent" onClick={(e) => menuClick(e, "/anatomy-introduction/adipose-tissue")} path="/anatomy-introduction/adipose-tissue">Adipose tissue</li>
                    <li id="men7" className="menu-indent" onClick={(e) => menuClick(e, "/anatomy-introduction/fibrous-tissue")} path="/anatomy-introduction/fibrous-tissue">Fibrous tissue</li>
                    <li id="men8" className="menu-indent" onClick={(e) => menuClick(e, "/anatomy-introduction/cartilage")} path="/anatomy-introduction/cartilage">Cartilage</li>
                    <li id="men9" className="menu-indent" onClick={(e) => menuClick(e, "/anatomy-introduction/bone")} path="/anatomy-introduction/bone">Bone</li>
                    <li id="men10" className="menu-indent" onClick={(e) => menuClick(e, "/anatomy-introduction/blood")} path="/anatomy-introduction/blood">Blood</li>
                <li id="men11" className="ruler" onClick={(e) => menuClick(e, "/anatomy-introduction/adipose-tissue-in-detail")} path="/anatomy-introduction/adipose-tissue-in-detail">Adipose tissue in detail</li>
                <li id="men12" onClick={(e) => menuClick(e, "/anatomy-introduction/types")} path="/anatomy-introduction/types">Types</li>
                <li id="men13" onClick={(e) => menuClick(e, "/anatomy-introduction/fat-distribution")} path="/anatomy-introduction/fat-distribution">Fat distribution</li>
                <li id="men14" onClick={(e) => menuClick(e, "/anatomy-introduction/factors-affecting-fat-distribution")} path="/anatomy-introduction/factors-affecting-fat-distribution">Factors affecting fat distribution</li>
            </ul>
        </div>
    );
};


export default Submenu;