import React, { useContext } from 'react';

const refContext = React.createContext();
const refUpdateContext = React.createContext();



export function useRefs() {
   return useContext(refContext);
}

export function useRefUpdate() {
    return useContext(refUpdateContext)
}

export function RefProvider({ children }) {

    const [refOpen,setRefOpen] = React.useState(false);
    

    function setRef() {
        const refIsOpen = !refOpen
        setRefOpen(refIsOpen);
        console.log(refOpen);
    }
    
    return (

        <refContext.Provider value={refOpen}>
            <refUpdateContext.Provider value={setRef}>
                {children}
            </refUpdateContext.Provider>
        </refContext.Provider>

    )
}