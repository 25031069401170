import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './GlobalCloseButton.css'

const GlobalCloseButton = (props) => {
    return (
        <div className="global-close" onClick={props.btnHandle}></div>
    );
};
export default GlobalCloseButton;