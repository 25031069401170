import React from 'react';

import { useModalUpdate, useModalContentUpdate } from "../../../../components/context/modalContext";
import { useModalScrollUpdate } from "../../../../components/context/mixContext";
import AdiposeTissuePopup from './AdiposeTissuePopup';
import { gaButtonClick } from '../../../../utils/gatags';

import "../Introduction-fragment.css";
import "./AdiposeTissue.css"


const AdiposeTissue = () => {


    const hcpmodalSet = useModalUpdate(true);
    const extlinkSet = useModalContentUpdate(true);
    const modScrollSet = useModalScrollUpdate();

    const setModalContent = (_component, _scrollmode, _e) => {
        hcpmodalSet(true);
        extlinkSet(AdiposeTissuePopup);
        modScrollSet(true);
    }
    const clickPopup = () => {
        //ADD TO OTHER LANGUAGES =====
        gaButtonClick("adipose-tissue-button","click", 'cellulite-modal')
        // ===
        setModalContent();
    }

    return (
        <div className="intro-anim-content">
            <p className="explore-number">07</p>
            <h1>Adipose Tissue</h1>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Structure<sup>1</sup></h2>
                    <p>Adipose tissue – or ‘fat’ – is mainly composed of cells called adipocytes. The space between adipocytes is occupied by areolar tissue, reticular tissue and blood capillaries. Adipocytes may occur alone or in small clusters. </p>
                    <p>As white fat is the most significant type of adipose tissue in humans, white fat adipocytes are of particular interest. These cells are 50 µm in diameter on average, but the sizes may vary in individuals.</p>
                    <p>White fat adipocytes contain a single large globule of triglyceride. The cytoplasm is restricted to a thin layer immediately beneath the plasma membrane, with the nucleus pushed to the edge of the cell.</p>
                    <div className="btn btn-glow" onClick={()=>clickPopup()}>Click here for more information on cellulite</div>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-adipose-tissue-fig1@2x.png")} alt="Intro adipose tissue" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdiposeTissue;