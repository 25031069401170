import React, { useState, useEffect } from "react";
import LeftMenu from "../../../components/LeftMenu/LeftMenu";
import { useWinSize, useWinSizeUpdate } from "../../../components/context/mixContext";
import Submenu from "../../../components/Submenu/Submenu";
import FatDistribution from "../../../pages/AnatomyIntroduction/IntroductionFragments/FatDistribution/FatDistribution";
import { gaPageView } from '../../../utils/gatags';

import "../Introduction.css";
import JobCode from "../../../components/JobCode/JobCode";



const IntroFatDistribution = () => {

  gaPageView(
    "introduction-fat-distribution",
    "pageview",
    window.location.pathname,
  )

  const [menuState, setMenuState] = useState(true);
  // const submenu = document.getElementById("submenu");
  const IntroWinSize = useWinSize();
  const IntroWinSizeSet = useWinSizeUpdate();
  let submenu

  useEffect(() => {
    submenu = document.getElementById("submenu");

  })

  const menuToggle = () => {
    setMenuState(!menuState);
    submenu.classList.toggle("show-submenu");
  }
  
  
  return (
    <>
      <section id="welcome" className="section-wrap">

        <LeftMenu menuToggle={menuToggle} activeBtns={[true,true,true,true,true,true,true]} withFooter="btm0" logoutonly={false} />

        <div className="welcome-content-wrap">
          <Submenu />
          <div id="intro-content" className={IntroWinSize[0] > 768 ? "right intro-content" : "right intro-content full-width"}>
            <FatDistribution />
            {/* <div className="job-code"><p>UK-CSC-230171 | August 2023</p></div> */}
            <JobCode />
          </div>
        </div>
        
      </section>
    </>
  );

};

export default IntroFatDistribution;
