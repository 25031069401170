import React, { useContext } from 'react';

const tabIndexContext = React.createContext();
const tabIndexUpdateContext = React.createContext();
const modalScrollContext = React.createContext();
const modalScrollUpdateContext = React.createContext();

const slideIndexContext = React.createContext();
const slideIndexUpdateContext = React.createContext();

const winSizeContext = React.createContext();
const  winSizeUpdateContext = React.createContext();


export function useTabIndex() {
    return useContext(tabIndexContext);
}
export function useTabIndexUpdate() {
    return useContext(tabIndexUpdateContext);
}

export function useModalScroll() {
    return useContext(modalScrollContext);
}
export function useModalScrollUpdate() {
    return useContext(modalScrollUpdateContext);
}

export function useSlideIndex() {
    return useContext(slideIndexContext);
}
export function useSlideIndexUpdate() {
    return useContext(slideIndexUpdateContext);
}

export function useWinSize() {
    return useContext(winSizeContext);
}
export function useWinSizeUpdate() {
    return useContext(winSizeUpdateContext);
}





export function MixProvider({ children }) {
    
    const [tabIndex,setTabIndex] = React.useState(0);
    const [modalScroll,setModalScroll] = React.useState(true);
    const [slideIndex,setSlideIndex] = React.useState(0);
    const [winSize,setWinSize] = React.useState([window.innerWidth,window.innerHeight]);

    function setModalTabIndex(_index) {
        setTabIndex(_index);
    }

    function setModScroll(_index) {
        setModalScroll(_index);
    } 

    function setSlIndex(_index) {
        setSlideIndex(_index);
    }

    function setWindowSize(_dimensions) {
        setWinSize(_dimensions);
    }
    
    return (

        <winSizeContext.Provider value={winSize}>
            <winSizeUpdateContext.Provider value={setWindowSize}>

                <slideIndexContext.Provider value={slideIndex}>
                    <slideIndexUpdateContext.Provider value={setSlIndex}>
                
                        <tabIndexContext.Provider value={tabIndex}>
                            <tabIndexUpdateContext.Provider value={setModalTabIndex}>
                                <modalScrollContext.Provider value={modalScroll}>
                                    <modalScrollUpdateContext.Provider value={setModScroll}>
                                        {children}
                                    </modalScrollUpdateContext.Provider>
                                </modalScrollContext.Provider>
                            </tabIndexUpdateContext.Provider>
                        </tabIndexContext.Provider>

                    </slideIndexUpdateContext.Provider>
                </slideIndexContext.Provider>

            </winSizeUpdateContext.Provider>
        </winSizeContext.Provider>
                    
    )
}
