import React from 'react';

import "../Introduction-fragment.css";
import "./AdiposeTissueDetail.css"

const AdiposeTissueDetail = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">12</p>
            <h1>Adipose Tissue In Detail</h1>
            <div className="fw-text">
                <h2>Functions<sup>2</sup></h2>
                <p>Adipose tissue serves as an energy reservoir, insulates against heat loss and provides a protective padding for organs.</p>
            </div>
            <div className="fw-text">
                <h2>Development<sup>2</sup></h2>
                <p>Adipose tissue is formed during foetal development and accounts for approximately 16% of total body weight at birth. Adipose tissue enlargement first peaks directly after birth, then again between the ages of 9 and 13.</p>
            </div>
            <div className="fw-text">
                <h2>Pattern and mass</h2>
                <p>Both the mass and pattern of distribution of adipose tissue are affected by:<sup>2</sup></p>
                <div className="atd-ill-wrap">
                    <div className="atd-ill">
                        <div className="atd-circle">
                            <p>Hormones</p>
                        </div>
                        <div className="atd-circle">
                            <p>Growth factors</p>
                        </div>
                        <div className="atd-circle">
                            <p>Nutrients</p>
                        </div>
                        <div className="atd-circle">
                            <p>Cytokines</p>
                        </div>
                    </div>
                </div>
                <h3>Hormones</h3>
                <ul>
                    <li>Hormone-independent<sup>3</sup> and age-specific<sup>4</sup> processes are associated with the increase in total body weight. However, several studies have shown the hormone-related aspect of weight behaviour<sup>5,6</sup></li>
                    <li>Body fat distribution such as gynoid fat distribution to the hips and thighs is often found in women<sup>7,8</sup></li>
                    <li>However, as oestrogen levels decrease during menopause, fat distribution is shifted to an android (abdominal) pattern with loss of subcutaneous fat</li>
                </ul> 
            </div>
        </div>
    );
};

export default AdiposeTissueDetail;