import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const MarcellusAsHermesLogios = () => {

    gaModalContent(
        "anatomy-atatue-info-marcellus-as-hermes-logios",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-marcellus-as-hermes-logios@2x.png")} alt="Marcellus as hermes logios" />
                </div>
                <div className="width60">
                    <p className="explore-number">Cleomenes the Athenian</p>
                    <h2>Marcellus as Hermes Logios</h2>
                    <p>Sculpture of Marcellus the Younger as Hermes Logios, the god of eloquence. It was executed in marble (1.80 metres in height) circa 20 BC (i.e. 2 years after the nominal subject’s death, possibly on his uncle Augustus’s personal order as a funerary monument), and was signed by Cleomenes the Athenian. Before 1590 it was housed in Pope Sixtus V’s villa on the Esquiline Hill. It was bought from the papal collections in 1664 by Louis XIV of France and placed in the “galerie des Glaces” at the Palace of Versailles. Napoleon brought it from there to the Louvre, Paris in 1802, where it now resides.</p>
                </div>
            </div>
        </>  
    );
};

export default MarcellusAsHermesLogios;