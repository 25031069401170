import React from 'react';
import './SliderSwitch.css';

const SliderSwitch = (props) => {



    const sliderClick = (_idx) => {
        document.querySelectorAll(".slider-slider-btn").forEach(function(allContent) {
            allContent.classList.remove("slb-active");
        });
        const sliderBtn = document.getElementById(`slb${_idx}`);
        sliderBtn.classList.add("slb-active");
        props.clickevent(_idx);
    }

    return (
        <div>
            <div className="slider-wrap" onClick={ (e) => sliderClick(0) }>
                <div className="slider-track">
                    <div id="slb0" className="slider-slider-btn slb-active"></div>
                </div>
                <div className="slider-label"><p>{props.lbl1}</p></div>
            </div>
            <div className="slider-wrap" onClick={ (e) => sliderClick(1) }>
                <div className="slider-track">
                    <div id="slb1" className="slider-slider-btn"></div>
                </div>
                <div className="slider-label"><p>{props.lbl2}</p></div>
            </div>
        </div>
    );
};

export default SliderSwitch;