import React, {useEffect} from 'react';
import { useRefs, useRefUpdate } from "../context/refContext";
import CloseButton from '../CloseButton/CloseButton';
import GlobalCloseButton from '../GlobalCloseButton/GlobalCloseButton';
import { gaModalContent } from '../../utils/gatags';

import './References.css';

const References = () => {

    const refState = useRefs(false);
    const setRefState = useRefUpdate();

    const toggleRefs = () => {
        setRefState(false);
    }

    useEffect(() => {



    });

    gaModalContent(
        "references",
        "modal-view",
        "/references"
    );

    return (
        <div className={refState ? "referenceContainer showref" : "referenceContainer"} onClick={toggleRefs}>
            <div className='reference'>
                <div className="references-head">
                    <h2>References</h2>
                    <GlobalCloseButton btnHandle={toggleRefs} />
                </div>
                <div className="ref-content">
                    <ol>
                        <li>Standring S <i>et al. Gray’s Anatomy: The Anatomical Basis of Clinical Practice</i>. Elsevier, 2015.</li>
                        <li>Frühbeck G, Gómez-Ambrosi J. Adipose Tissue: Structure, Function and Metabolism. <i>Encyclopedia of Human Nutrition</i>. 3rd ed. Academic Press, 2012. p1&ndash;13.</li>
                        <li>Davis SR <i>et al</i>. Understanding weight gain at menopause. <i>Climacteric</i>. 2012;15(5):419&ndash;29.</li>
                        <li>Institute of Medicine (US) Subcommittee on Military <i>Weight Management. Weight Management: State of the Science and Opportunities for Military Programs</i>. National Academies Press (US), 2004. doi:10.17226/10783.</li>
                        <li>Mullur R <i>et al</i>. Thyroid hormone regulation of metabolism. <i>Physiological reviews</i>. 94(2), 355&ndash;82 (2014).</li>
                        <li>van der Valk ES <i>et al</i>. Stress and Obesity: Are There More Susceptible Individuals? <i>Curr Obes Rep</i>. 2018;7(2):193&ndash;203.</li>
                        <li>Lizcano F, Guzmá G. Estrogen Deficiency and the Origin of Obesity during Menopause. <i>BioMed Res Int</i>. 2014;2014:757461.</li>
                        <li>Frank AP <i>et al</i>. Determinants of body fat distribution in humans may provide insight about obesity-related health risks. <i>J Lipid Res</i>. 2019;60(1):1710&ndash;1719.</li>
                        <li>Saely CH <i>et al</i>. Brown versus white adipose tissue: A mini-review. <i>Gerontology</i>. 2012; 58(1):15&ndash;23.</li>
                        <li>Lee MJ <i>et al</i>. Adipose tissue heterogeneity: Implication of depot differences in adipose tissue for obesity complications. <i>Mol Aspects Med</i>. 2013;34(1):1&ndash;11.</li>
                        <li>Luong Q <i>et al</i>. Deciphering white adipose tissue heterogeneity. <i>Biology (Basel)</i>. 2019;8(2):23.</li>
                        <li>Nauli AM, Matin S. Why Do Men Accumulate Abdominal Visceral Fat? <i>Front Physiol</i>. 2019;10:1486.</li>
                        <li>Ingargiola MJ <i>et al</i>. Cryolipolysis for fat reduction and body contouring: safety and efficacy of current treatment paradigms. <i>Plast Reconstr Surg</i>. 2015;135(6):1581&ndash;1590.</li>
                        <li>Rønn PF <i>et al</i>. Ethnic differences in anthropometric measures and abdominal fat distribution: A cross-sectional pooled study in Inuit, Africans and Europeans. <i>J Epidemiol Community Health</i>. 2017;71(6):536&ndash;543.</li>
                        <li>Nazare JA <i>et al</i>. Ethnic influences on the relations between abdominal subcutaneous and visceral adiposity, liver fat, and cardiometabolic risk profile: The international study of prediction of intra-abdominal adiposity and its relationship with cardiometabolic risk/intra-abdominal adiposity. <i>Am J Clin Nutr</i>. 2012;96;714&ndash;726.</li>
                        <li>Lear SA <i>et al</i>. Visceral adipose tissue accumulation differs according to ethnic background: Results of the Multicultural Community Health Assessment Trial (M-CHAT). <i>Am J Clin Nutr</i>. 2012;86(2):353&ndash;359.</li>
                        <li>Maskarinec G <i>et al</i>. Body Fat Distribution, Glucose Metabolism, and Diabetes Status Among Older Adults: The Multiethnic Cohort Adiposity Phenotype Study. <i>J Epidemiol</i>. 2021 Feb 27. [Online ahead of print].</li>
                        <li>Sommer C <i>et al</i>. Ethnic differences in BMI, subcutaneous fat, and serum leptin levels during and after pregnancy and risk of gestational diabetes. <i>Eur J Endocrinol</i>. 2015;172(6):649&ndash;656.</li>
                        <li>Allergan Inc. CoolSculpting® system user manual. BRZ-101-TUM-EN4-K. August 2020.</li>
                        <li>Mendieta CG, Sood A. Classification System for Gluteal Evaluation: Revisited. <i>Clin Plast Surg</i>. 2018;45(2):159&ndash;177.</li>
                        <li>Thomas WW, Bloom JD. Neck contouring and treatment of submental adiposity. <i>J Drugs Dermatol</i>. 2017;16(1):54&ndash;57.</li>          
                        <li>Clarke B. Normal Bone Anatomy and Physiology. <i>Clin J Am Soc Nephrol</i> 2008;3:S131&ndash;S39.</li>          
                        <li>Winslow V. <i>Classic Human Anatomy in Motion</i>. (Watson-Guptil Publications 2015).</li>          
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default References;