import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const PoseidonOfMelos = () => {

    gaModalContent(
        "anatomy-atatue-info-poseidon-of-melos",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-poseidon-of-melos@2x.png")} alt="Poseidon of melos" />
                </div>
                <div className="width60">
                    <p className="explore-number">Unknown artist</p>
                    <h2>Poseidon of melos</h2>
                    <p>The Poseidon of Melos is a statue dated to the last quarter of the second century BCE. Found in 1877 on the island of Melos, it is made from Parian Marble and has a height of 2.35 metres, making it larger than life size. The Greek god of the sea is depicted naked to the waist in an awe-inspiring pose. His muscular right arm is raised, probably to hold a trident, the original version of which is now lost in antiquity. The pose is a standard one seen in representations of the gods Zeus and Hades, as well as Poseidon.</p>
                </div>
            </div>
        </>  
    );
};

export default PoseidonOfMelos;