import React, { useState } from 'react';
import SliderSwitch from '../../../../components/SliderSwitch/SliderSwitch';
import SingleSliderSwitch from '../../../../components/SingleSliderSwitch/SingleSliderSwitch';


import "../Introduction-fragment.css";
import "./Types.css"

const Types = () => {

    const [slidervalue, setSliderValue] = useState(0);

    const sliderClick = (_idx) => {
        setSliderValue(parseInt(_idx));
    }

    return (
        <div className="intro-anim-content">
            <p className="explore-number">13</p>
            <h1>Types</h1>
            <div className="fw-text">
                <p>There are two main types of adipose tissue: white and brown.<sup>1,9</sup> </p>
            </div>
            <div className="text50-text50">
                <div className="width50">
                    <h3>White adipose tissue</h3>
                    <p>This is the most abundant type of adipose tissue.<sup>1</sup> It stores energy, increases with age and is associated with increased risk of obesity-related disorders when present in large amounts:<sup>9</sup> </p>
                </div>
                <div className="width50">
                    <h3>Brown adipose tissue</h3>
                    <p>Brown adipose tissue is abundant in newborns.<sup>9</sup> It is also found in the cervical-supraclavicular, perirenal and paravertebral regions of adults:<sup>10</sup></p>
                </div>
            </div>
            <div className="fragment-wrapper-2col frag-inset">
                <div className="widthSwitchImg">
                    <div className="img">
                        <div id="types0" className={ parseInt(slidervalue) === 1 ? "switch-image switch-active" : "switch-image" }>
                            <div className="into-img">
                                <img src={require("../../../../assets/images/intro-types-fig1@2x.png")} alt="introduction types" />
                            </div>
                        </div>
                        <div id="types1" className={ parseInt(slidervalue) === 0 ? "switch-image switch-active" : "switch-image" }>
                            <div className="into-img">
                                <img src={require("../../../../assets/images/intro-types-fig2@2x.png")} alt="introduction types" />
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div className="width280px">
                    <h3>Turn on and off to see the distribution on the image</h3>
                    {/* <SingleSliderSwitch lbl="White adipose tissue" _idx="0" func={sliderClick} />
                    <SingleSliderSwitch lbl="Brown adipose tissue" _idx="1" func={sliderClick} /> */}
                    <SliderSwitch lbl1="White adipose tissue" lbl2="Brown adipose tissue" clickevent={sliderClick} />
                </div>
            </div>
        </div>
    );
};

export default Types;