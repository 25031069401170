import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const EnlargeArmsMale = ({_muscle}) => {

    gaModalContent(
        "anatomy-atatue-info-abdomen-male",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div id="enlarge" className="img-holder">
                {_muscle ? 
                    <img src={require("../../../assets/images/popup-arms-male.png")} alt="Male arms" /> :
                    <img src={require("../../../assets/images/popup-arms-male-fat.png")} alt="Male arms" />
                }
            </div>
        </>    
    );
};

export default EnlargeArmsMale;