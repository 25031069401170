import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const Discobolus = () => {

    gaModalContent(
        "anatomy-atatue-info-discobolus",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-discobolus@2x.png")} alt="discobolus" />
                </div>
                <div className="width60">
                    <p className="explore-number">Myron</p>
                    <h2>Discobolus</h2>
                    <p>The Discobolus or ‘discus thrower’ is attributed to the Greek sculptor, Myron, who was famed in antiquity for his depiction of athletes. The statue was completed at the start of the classical period, around 460–450 BCE. While the original Greek bronze is lost, the work is known through numerous Roman copies, including those in marble as shown here. The work captures a moment in time as the athlete turns to throw. A sublime study of energy, rhythm and control. </p>
                </div>
            </div>
        </>  
    );
};

export default Discobolus;