import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreThigh = () => {

    gaModalContent(
        "anatomy-explore-thigh",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">04</p>
                    <h2>Thighs<sup>1</sup></h2>
                    <div className="text50-img50 mt0">
                        <div className="width50">
                            <p>The anterior (front) compartment of the thigh contains the large <i>quadriceps femoris</i> muscle, the most powerful muscle in the body.</p> 
                            <p>As the prefix ‘quad’ indicates, this muscle has four heads: the <i>rectus femoris, vastus lateralis, vastus medialis</i> and <i>vastus intermedius</i>. These all converge on a single quadriceps (patellar) tendon.</p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-thigh-fig1@2x.png")} alt="Explore thigh" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>The posterior (rear) compartment of the thigh contains three muscles commonly known as the ‘hamstrings’ (<i>biceps femoris, semitendinosus and semimembranosus</i>):</p>
                            <p>It should also be noted that the sciatic nerve runs down the thigh.</p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-thigh-fig2@2x.png")} alt="Explore thigh"/>
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>When viewed in cross-section, a layer of subcutaneous fat surrounding the thigh muscles is clearly visible:</p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-thigh-fig3@2x.png")} alt="Explore thigh"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreThigh;