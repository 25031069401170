import React from 'react';
import Icon from '../../assets/images/gallery-icon.png';

import "./PlateButton.css"

const PlateButton = (props) => {

    const mystyle = {
        width: "36px",
        height: "36px",
        pointerEvents: "none",
        margin: "-5px 10px 0 0",
        backgroundImage: `url(${Icon})`,
        backgroundSize: "90%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      };

    return (
        <div className="plate-button" onClick={()=>props.btnhandle(props.btnindex)}>
            
            <div className="pb-imgs">
                <div className="pb-abs pb-cornerTL"></div>
                <div className="pb-abs pb-cornerTR"></div>
                <div className="pb-abs pb-cornerBL"></div>
                <div className="pb-abs pb-cornerBR"></div>
                <div className="pb-abs pb-lineTop"></div>
                <div className="pb-abs pb-lineBottom"></div>
                <div className="pb-abs pb-lineLeft"></div>
                <div className="pb-abs pb-lineright"></div>
            </div>
            <div className="pb-hover"></div>
            <div className="pb-label">
                {props.icon==="true" ? <div style={mystyle} ></div> : ""}
                {props.plateImg !== undefined ? <img src={props.plateImg} alt="plate Icon"/> : ""}
                <p>{props.label}</p>
            </div>
        </div>
    );
};

export default PlateButton;