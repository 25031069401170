import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const TheThinker = () => {

    gaModalContent(
        "anatomy-atatue-info-the-thinker",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-the-thinker@2x.png")} alt="The Thinker" />
                </div>
                <div className="width60">
                    <p className="explore-number">Auguste Rodin</p>
                    <h2>The Thinker</h2>
                    <p>This famous bronze by Rodin, usually placed on a stone pedestal, depicts a heroically-sized male figure in deep contemplation. He leans at the waist, right elbow on left thigh, with his chin resting on the back of his hand. Often used to represent philosophy, the piece was initially conceived as part of another work commissioned in 1880. However, the first of the familiar bronze castings was made in 1904 and is now exhibited at the Musée Rodin in Paris.</p>
                </div>
            </div>
        </>  
    );
};

export default TheThinker;