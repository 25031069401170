import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const TheNymphSalmacis = () => {

    gaModalContent(
        "anatomy-atatue-info-the-nymph-salmacis",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-the-nymph-salmacis@2x.png")} alt="The Nymph Salmacis" />
                </div>
                <div className="width60">
                    <p className="explore-number">Julien Charles Dubois</p>
                    <h2>The Nymph Salmacis</h2>
                    <p>This stunning marble by Julien-Charles Dubois depicts the nymph Salmacis in a state of repose. A diaphanous covering drapes suggestively below her waist, while her head is adorned with an intricately carved floral diadem, adding to the overall feeling of grace, poise and beauty. The piece was commissioned by King Charles X in 1826 (following a celebrated earlier work) and first exhibited in 1837. Today, it is distinguished by its near perfect condition, with original surface finish. </p>
                </div>
            </div>
        </>  
    );
};

export default TheNymphSalmacis;