import React from 'react';

import "../Introduction-fragment.css";
import "./FactorsAffectingFatDistribution.css"

const FactorsAffectingFatDistribution = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">15</p>
            <h1>Factors Affecting Fat Distribution</h1>
            <div className="text50-text50">
                <div className="width50">
                    <h3>Age</h3>
                    <p>There are changes in the distribution, composition and abundance of adipose tissue as humans age, with a shift from accumulation of subcutaneous fat to visceral fat.<sup>8</sup></p>
                    <p>Different fat depots also change at different rates as we grow older.<sup>9</sup> For example, subcutaneous fat is lost first, while visceral fat is relatively preserved.<sup>9</sup></p>
                    <p>Loss of subcutaneous fat with age is associated with aesthetic changes, such as thinning of the skin, increased wrinkles and sunken cheeks.<sup>8</sup></p>
                    <h3>Sex</h3>
                    <p>Adult men have a lower percentage of body fat than adult women, but men  have a higher percentage of visceral adipose tissue.<sup>2,8</sup></p>
                    <p>Subcutaneous adipose tissue in men is mainly found:</p>
                    <ul>
                        <li>In the nape of the neck </li>
                        <li>Over the deltoid and triceps muscles </li>
                        <li>In the lower back</li>
                    </ul>
                    <p>Women have more subcutaneous fat than men in the: </p>
                    <ul>
                        <li>Buttocks-thigh region</li>
                        <li>Skinfold of the upper eyelid</li>
                        <li>Breasts</li>
                    </ul>
                    <p>Premenopausal women have more subcutaneous adipose tissue in the abdominal and buttock-thigh area than men.<sup>8</sup> But after menopause, women tend to accumulate more visceral adipose tissue in the abdomen, similar to men.<sup>8</sup></p>
                </div>
                <div className="width50">
                    <h3>Ethnicity</h3>
                    <p>A person’s ethnic background may influence their fat distribution.<sup>11,14–18</sup></p> 
                    <p>For example:</p>
                    <ul>
                        <li>African Americans and Hispanics have relatively less visceral adipose tissue than Caucasians<sup>10</sup></li>
                        <li>Inuit and Africans generally have less visceral adipose tissue and subcutaneous adipose tissue than Caucasians<sup>14</sup></li>
                    </ul>
                    <p>In a study that evaluated white, African Caribbean black, Hispanic, East Asian and Southeast Asian ethnic groups:<sup>15</sup></p>
                    <ul>
                        <li>Caucasians had the highest visceral adipose tissue area</li>
                        <li>East Asians had the lowest visceral adipose tissue area</li>
                        <li>Visceral/subcutaneous adipose tissue ratio was higher in East Asian men than in the other ethnic groups</li>
                        <li>Visceral/subcutaneous adipose tissue ratio was highest in East and Southeast Asian women</li>
                    </ul>
                    <div className="into-img mb20">
                        <img src={require("../../../../assets/images/intro-fat-distribution-fig2@2x.png")} alt="introduction to fat distribution" />
                    </div>
                    <p>Chinese and South Asians have been found to have a greater amount of abdominal adipose tissue – and an even greater amount of visceral adipose tissue – than Caucasians.<sup>16</sup></p>
                    <p>Visceral/subcutaneous adipose tissue ratio was found to be:<sup>17*</sup> </p>
                    <ul>
                        <li>Highest among Japanese Americans</li>
                        <li>Lowest among African Americans</li>
                    </ul>
                    <p>Fourteen weeks after giving birth, South Asian women were found to have more subcutaneous adipose tissue than European women, despite having a lower BMI.<sup>18</sup></p>
                </div>
            </div>
            <div className="fw-text">
                <div className="rule-footnote">
                    <p><sup>*</sup>other ethnic groups studied were white, Latino and Native Hawaiian</p>
                </div>
            </div>
        </div>
    );
};

export default FactorsAffectingFatDistribution;