import React, { useEffect } from "react";
import { usePIs, usePIUpdate } from "../context/piContext";
import CloseButton from "../CloseButton/CloseButton";
import GlobalCloseButton from "../GlobalCloseButton/GlobalCloseButton";
import { gaModalContent } from "../../utils/gatags";

import "./PI.css";

const PI = () => {
  const piState = usePIs(false);
  const setPIState = usePIUpdate();

  const togglePIs = () => {
    setPIState(false);
  };

  gaModalContent("AE", "modal-view", "/PI");

  return (
    <div
      className={piState ? "piContainer showref" : "piContainer"}
      onClick={togglePIs}
    >
      <div className="pi">
        <div className="pis-head">
          <h2>Important Safety Information:</h2>
          <GlobalCloseButton btnHandle={togglePIs} />
        </div>
        <div className="ref-content">
          <p>
            During the procedure patients may experience sensations of pulling, tugging, mild pinching, intense cold, tingling, stinging, aching and cramping at the treatment site. These sensations may subside as the area becomes numb. Following the procedure, typical side effects include redness, swelling, blanching, bruising, firmness, tingling, stinging, tenderness, cramping, aching, itching, skin sensitivity and numbness. Numbness can persist for up to several weeks. A sensation of fullness in the back of the throat may occur after submental treatment. These rare and very rare adverse events have the following reported rates (approximate occurrences per number of treatments): paradoxical hyperplasia (1/3000 [0.033%]); late-onset pain (1/6000 [0.017%]); severe pain (1/6000 [0.017%]); hyperpigmentation (1/11000 [0.009%]); freeze burn (1/15000 [0.006%]); treatment area demarcation (1/20000 [0.005%]); vasovagal symptoms (1/30000 [0.003%]); subcutaneous induration (1/30000 [0.003%]); cold panniculitis (1/60000 [0.002%]) and hernia (1/185000 [0.001%]).<sup>1</sup> The CoolSculpting<sup>®</sup> procedure is not for everyone. Patients should not have the CoolSculpting<sup>®</sup>{" "} procedure if they suffer from cryoglobulinemia, cold agglutinin disease or paroxysmal cold haemoglobinuria.<sup>1</sup> The CoolSculpting<sup>®</sup> procedure is not a treatment for obesity.<sup>2</sup> CoolSculpting<sup>®</sup> is not a weight-loss procedure and should not replace a healthy diet and active lifestyle.
          </p>
          <p className="mg-top">References</p>
          <ol>
            <li>
              Allergan. CoolSculpting<sup>®</sup> system user manual. BRZ-101-TUM-EN4-K. August 2020.
            </li>
            <li>
              FDA 510(K) summary document, January 2020. Available at: <a target="_blank" href="https://www.accessdata.fda.gov/cdrh_docs/pdf19/K193566.pdf">https://www.accessdata.fda.gov/cdrh_docs/pdf19/K193566.pdf</a>. [Accessed August 2023]
            </li>
          </ol>
          <p className="mg-top">The suitability of CoolSculpting<sup>®</sup> treatment on an individual patient basis should always be considered as part of the assessment and consultation process. CoolSculpting<sup>®</sup> is not suitable for all patients. Please consult the ‘Directions for Use’ prior to the procedure. Patient results may vary.</p>
          <p className="mg-top">
            To learn more about CoolSculpting<sup>®</sup> and/or CoolSculpting<sup>®</sup> Elite, please visit <a href="https://uk.coolsculpting.com/" target="_blank">https://uk.coolsculpting.com/</a> for further information. Images are for illustrative purposes only. Promotional material provided by Allergan Aesthetics, an AbbVie company. This content is intended for UK & Ireland adult (>18yrs) audience only.
          </p>

          <p className="mg-top mg-bt">
            CoolSculpting<sup>®</sup>, the CoolSculpting<sup>®</sup> logo, and the Snowflake design are registered trademarks of Allergan Aesthetics, an AbbVie company. ZELTIQ<sup>®</sup> is part of Allergan Aesthetics, an AbbVie company. © 2023 AbbVie. All rights reserved. For further information on CoolSculpting<sup>®</sup>{" "} please visit <a href="https://uk.coolsculpting.com/" target="_blank">https://uk.coolsculpting.com/</a>
          </p>

          <div className="ae-border">
            <p className="bold mg-bt">Adverse Events should be reported</p>
            <p className="mg-bt">
              UK Reporting forms and information can be found at <a href="https://yellowcard.mhra.gov.uk" target="_blank">https://yellowcard.mhra.gov.uk</a> or via the MHRA Yellow Card app, available at Google Play or Apple App stores. Adverse events should also be reported to AbbVie on coolsculpting.intlsupport@allergan.com
            </p>

            <p>
              Irish HCPs are asked to report any suspected adverse reactions via HPRA Pharmacovigilance website: <a href="https://www.hpra.ie" target="_blank">https://www.hpra.ie</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PI;
