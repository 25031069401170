import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const VenusDeMilo = () => {

    gaModalContent(
        "anatomy-atatue-info-venus-de-milo",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-venus-de-milo@2x.png")} alt="Venus de Milo" />
                </div>
                <div className="width60">
                    <p className="explore-number">Alexandros of Antioch </p>
                    <h2>Venus de Milo</h2>
                    <p>The Venus de Milo is one of the most famous works of ancient Greek sculpture. Created sometime between 150 and 125 BCE, the statue was originally attributed to Praxiteles. However, based on an inscription on its plinth, it is now widely agreed to be the work of Alexandros of Antioch. The statue is believed to depict Venus, the Roman counterpart of the Greek goddess, Aphrodite. While the work is famously missing both arms, it is interesting to note that part of one arm and the original plinth were lost after the statue’s rediscovery. </p>
                </div>
            </div>
        </>  
    );
};

export default VenusDeMilo;