import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreFlanks = () => {

    gaModalContent(
        "anatomy-explore-flanks",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">02</p>
                    <h2>Flanks</h2>
                    <p>The term ‘flanks’ is commonly used to describe fleshy areas to the left and right of the abdomen, between the ribs and hips. These areas are part of the lumbar region:<sup>1</sup></p>
                    <div className="width100">
                        <div className="explore-full-img">
                            <div className="img">
                            <img className="img-shadow" src={require("../../../assets/images/explore-flank-fig1@2x.png")} alt="Explore flanks" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreFlanks;