import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreButtocks = () => {

    gaModalContent(
        "anatomy-explore-buttocks",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">03</p>
                    <h2>Buttocks</h2>
                    <div className="text50-img50 mt0">
                        <div className="width50">
                        <p>The overall anatomy of the buttocks is the same in men and women:<sup>1</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-buttocks-fig1@2x.png")} alt="Explore buttocks" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>There are three gluteal muscles in all: the <i>gluteus maximus, gluteus medius</i> and <i>gluteus minimus.</i><sup>1</sup> The <i>gluteus maximus</i> is the largest of these and forms most of the lean mass in a buttock:<sup>1</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-buttocks-fig2@2x.png")} alt="Explore buttocks" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>The overall shape of the buttocks depends on the underlying skeleton, the <i>gluteus maximus</i> muscle, the location and quantity of subcutaneous fat and the tightness of surrounding skin:<sup>20</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-buttocks-fig3@2x.png")} alt="Explore buttocks" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>It is important to note the position of the sciatic, femoral and pudendal nerves:<sup>1</sup></p>
                            <ul>
                                <li>The sciatic nerve runs down from the lower spine, through to the buttocks and the back of the thigh</li>
                                <li>The femoral nerve runs through the pelvis, then goes down the front of the leg</li>
                                <li>The pudendal nerve is the main nerve serving the perineum (i.e. the area between the anus and the genitalia)</li>
                            </ul>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-buttocks-fig4@2x.png")} alt="Explore buttocks" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreButtocks;