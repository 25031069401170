import React from "react";
import Content from "./layout/Content/Content";

import { ModalProvider } from "./components/context/modalContext";
import { RefProvider } from "./components/context/refContext";
import { PIProvider } from "./components/context/piContext";
import { MixProvider } from "./components/context/mixContext";
import PopupModal from "./components/PopupModal/PopupModal";
import References from "./components/References/References";


import './App.css';
import PI from "./components/PI/PI";

function App() {
  return (
    <MixProvider>
      <ModalProvider>
        <PIProvider>
        <RefProvider>
          <Content />
          <References />
          <PI />
        </RefProvider>
        </PIProvider>
        <PopupModal />
      </ModalProvider>
    </MixProvider>
  );
}

export default App;
