import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreAbdomen = () => {

    gaModalContent(
        "anatomy-explore-abdomen",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">01</p>
                    <h2>Abdomen</h2>
                    <p>The abdominal cavity has little skeletal support, but it is enclosed in layers of broad flat muscles, with fibres running in different directions, strengthening the abdominal wall. A layer of subcutaneous fat is present on top of these muscles.<sup>1</sup> </p>
                    <div className="text50-text50">
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-abdomen-fig1@2x.png")} alt="Explore abdomen" />
                            </div>
                        </div>
                        <div className="width50 lt-blue-wrapper">
                            <p>The effect of performing a cryolipolysis treatment on a patient who has a hernia in or adjacent to the treatment site has not been studied. The vacuum may apply pressure on a pre-existing hernia – or on a pre-existing structurally weak area such as a surgical scar – causing further complications. Because of this, patients should be assessed for evidence of pre-existing abdominal or femoral hernia prior to treatment.<sup>19</sup></p>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreAbdomen;