import React from 'react';

import "../Introduction-fragment.css";
import "./Cartilage.css"

const Cartilage = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">09</p>
            <h1>Cartilage<sup>1</sup></h1>
            <div className="fw-text">
                <p>Cartilage is a relatively stiff connective tissue with a flexible rubbery matrix. This structure enables cartilage to shape and support the nose and ears, for example, as well as partially enclose the larynx, trachea and thoracic cavity. Cartilage has no blood capillaries, so nutrition and waste removal are dependent on solute diffusion through the stiff matrix.</p>
            </div>
            <div className="width50">
                <div className="into-img">
                    <img className="img-shadow" src={require("../../../../assets/images/intro-cartilage-fig1@2x.png")} alt="introduction to cartilage" />
                </div>
            </div>
            {/* <div className="lt-blue-wrapper-2col">
                <div className="width50">
                    <h3>Elastic Cartilage</h3>
                    <p>Dense <b>regular</b> connective tissue in a tenson</p>
                    <ul>
                        <li>Contains densely packed, parallel, often wavy collagen fibres</li>
                        <li>Located in areas such as tendons and ligaments</li>
                        <li>Parallel arrangement of fibres is suited to stresses in predictable directions</li>
                    </ul>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-cartilage-fig1@2x.png")} alt="introduction to cartilage" />
                    </div>
                </div>
            </div> */}
            {/* <div className="lt-blue-wrapper-2col">
                <div className="width50">
                    <h3>Fibrocartilage</h3>
                    <p>Dense <b>regular</b> connective tissue in a tenson</p>
                    <ul>
                        <li>Contains densely packed, parallel, often wavy collagen fibres</li>
                        <li>Located in areas such as tendons and ligaments</li>
                        <li>Parallel arrangement of fibres is suited to stresses in predictable directions</li>
                    </ul>
                </div>
                <div className="width50">
                    <div className="img"></div>
                </div>
            </div> */}
        </div>
    );
};

export default Cartilage;