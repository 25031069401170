import TagManager from 'react-gtm-module'

const _lang = 'en'

export const gaAudioButton = (_category, _action, _location) => {
    // console.log(`Category: ${_category} - Action: ${_action} - Location: ${_location} - Language: ${_lang} - Event: eventTracking`)
    const tagManagerArgs = {
        dataLayer: {
            event: 'eventTracking',
            Category: _category,
            Action: _action,
            Location: _location,
            Language: _lang
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);

};

export const gaSliderView = (_category, _action, _location) => {
    // console.log(`Category: ${_category} - Action: ${_action} - Location: ${_location} - Language: ${_lang} - Event: eventTracking`)
    const tagManagerArgs = {
        dataLayer: {
            event: 'eventTracking',
            Category: _category,
            Action: _action,
            Location: _location,
            Language: _lang
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);

};

export const gaPageView = (_location, _title) => {
    // console.log(`Location: ${_location} - Title: ${_title} - Language: ${_lang} - Event: pageView`)
    const tagManagerArgs = {
        dataLayer: {
            event: "pageView",
            Category: "Page",
            Action: "Pageview",
            Label: _location,
            Title: _title,
            Language: _lang
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
    
};

export const gaButtonClick = (_category, _action, _location) => {
    // console.log(`Category: ${_category} - Action: ${_action} - Location: ${_location} - Language: ${_lang} - Event: eventTracking`)
    const tagManagerArgs = {
        dataLayer: {
            event: 'eventTracking',
            Category: _category,
            Action: _action,
            Label: _location,
            Language: _lang
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
    
};

export const gaModalContent = (_category, _action, _location) => {
    // console.log(`Category: ${_category} - Action: ${_action} - Location: ${_location} - Language: ${_lang} - Event: eventTracking`)
    const tagManagerArgs = {
        dataLayer: {
            event: 'eventTracking',
            Category: _category,
            Action: _action,
            Label: _location,
            Language: _lang
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
    
};