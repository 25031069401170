import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const AlexanderTheGreat = () => {

    gaModalContent(
        "anatomy-atatue-info-alexander-the-great",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-alexander-the-great@2x.png")} alt="Alexander the great" />
                </div>
                <div className="width60">
                    <p className="explore-number">Carlo Albacini</p>
                    <h2>Alexander The Great</h2>
                    <p>This imposing 18th-century bust of Alexander the Great is a copy of a much earlier work housed at the Capitoline Museum in Rome. The latter was copied numerous times by sculptors such as Carlo Albacini. Alexander the Great established the Macedonian Empire and was worshipped as a god by many nations, before dying at the age of just 32 years old. It is thus little wonder that depictions of him often display a combination of youthful vigour, power and nobility. </p>
                </div>
            </div>
        </>  
    );
};

export default AlexanderTheGreat;