import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const EnlargeFlanksFemale = ({_muscle}) => {

    gaModalContent(
        "anatomy-atatue-info-flanks-female",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div id="enlarge" className="img-holder">
                {_muscle ? 
                    <img src={require("../../../assets/images/popup-flanks-female.png")} alt="Flanks female" /> :
                    <img src={require("../../../assets/images/popup-flanks-female-fat.png")} alt="Flanks female" />
                }
            </div>
        </>
    );
};

export default EnlargeFlanksFemale;