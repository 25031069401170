import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const EnlargeAbdomenFemale = ({_muscle}) => {

    gaModalContent(
        "anatomy-atatue-info-abdomen-female",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div id="enlarge" className="img-holder enlarge">
                <div>
                    {_muscle ? 
                    <img src={require("../../../assets/images/popup-abdomen-female.png")} alt="Female abdomen" /> :
                    <img src={require("../../../assets/images/popup-abdomen-female-fat.png")} alt="Female abdomen" />
                    }
                </div>
            </div>
        </>
    );
};

export default EnlargeAbdomenFemale;