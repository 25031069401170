import * as React from "react";
import "./EnlargeImgs.css";

    const FlanksZoomLinks = (prop) => (
        <svg className="zoom-links" x="0px" y="0px"
                viewBox="0 0 1280 1552">
            <g id="lines">
                <path id="Path_936_00000083087695333755321030000004626713410901980335_" className="st0" d="M979.9,650.8l141,141.2h32.2"/>
                <path id="Path_935" className="st0" d="M169.4,727.6h53l97.9,76.2H374"/>
                <path id="Path_935_00000176010039155925510240000010910801800601330830_" className="st0" d="M938.5,926.9h54.8l97.9,76.2h61.8"/>
                <path id="Path_935_00000052104605073091844460000012885614620177132686_" className="st0" d="M167.6,943.5h54.8l97.9,76.2H374"/>
            </g>
            <g id="hs2">
                <g id="Component_37_14_00000179628279777515730300000014046471510224269215_" transform="translate(526.459 151.729)">
                    <path id="Path_6-2_00000128465664977804518240000000331922379298065068_" className="st1" d="M694.4,636.1c0,18.7-15.2,33.9-33.9,33.9
                        c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9C679.2,602.1,694.4,617.3,694.4,636.1L694.4,636.1z"/>
                    <path id="Path_7-2_00000045619608914035932990000007615109677145711520_" className="st1" d="M702.9,678.4L684.5,660"/>
                    <path id="Path_8-2_00000168815253709867504470000018361376172074350267_" className="st1" d="M660.5,623.3v25.4"/>
                    <path id="Path_9-2_00000011025140681231808560000012246295983413424031_" className="st1" d="M647.8,636.1h25.4"/>
                </g>
                <rect onClick={() => prop.func(1,1,"popup")} x="1142" y="742.8" className="st2" width="90" height="90"/>
            </g>
            <g id="hs1">
                <g id="Component_37_13_00000156581243906695320010000004859343152986913932_" transform="translate(1.5 1.5)">
                    <path id="Path_6_00000114794098558398706690000003962570498297660560_" className="st1" d="M166.1,725.3c0,18.7-15.2,33.9-33.9,33.9
                        S98.3,744,98.3,725.3s15.2-33.9,33.9-33.9S166.1,706.6,166.1,725.3L166.1,725.3z"/>
                    <path id="Path_7_00000068662978503787155270000000938240764484403329_" className="st1" d="M174.6,767.7l-18.4-18.4"/>
                    <path id="Path_8_00000111892104891949002790000017602301826116578207_" className="st1" d="M132.2,712.6V738"/>
                    <path id="Path_9_00000066516027785354570300000004457079646729552257_" className="st1" d="M119.5,725.3h25.4"/>
                </g>
                <rect onClick={() => prop.func(1,0,"popup")} x="88.7" y="681.8" className="st2" width="90" height="90"/>
            </g>
            <g id="statuelink1">
                {/* <rect id="statue2-hover" x="922" y="1334" className="statue-hover abd2-hover fade-transition" width="180" height="105"/>
                <rect onClick={() => prop.func(1,1,"statue")} x="922" y="1301.3" className="audio-fill flanks-statue" width="195" height="150"/> */}
                <rect x="922" y="1301.3" className="audio-fill flanks-statue" width="195" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="622" y="1334" className="statue-hover abd1-hover fade-transition" width="299" height="105"/>
                <rect onClick={() => prop.func(1,0,"statue")} x="600" y="1301.3" className="audio-fill flanks-statue" width="320" height="150"/> */}
                <rect x="600" y="1301.3" className="audio-fill flanks-statue" width="320" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(1,0,"audio")} x="900.2" y="1137.5" className="audio-fill flanks-statue" width="216.1" height="150"/> */}
            </g>





            <g id="info2">
                <g>
                    <path className="st4" d="M1220.9,1003.2c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9
                        S1220.9,984.4,1220.9,1003.2z M1191.9,994.3l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4
                        c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3s2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7
                        c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9
                        l7.4-25.6H1191.9z M1188.1,979.2c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5
                        s1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5C1190.4,977.7,1189.2,978.2,1188.1,979.2z"/>
                        <rect onClick={() => prop.func(1,1,"statue")} x="1142" y="958.2" className="st3" width="90" height="90"/>
                </g>
            </g>
            <g id="info1">
                <g>
                    <path className="st4" d="M167.6,943.5c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        S167.6,924.8,167.6,943.5z M138.7,934.6l-12.2,2L126,938c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4
                        c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5
                        l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7
                        c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H138.7z M134.9,919.6c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7s2.3,1.5,3.7,1.5
                        c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5C137.1,918.1,135.9,918.6,134.9,919.6z"/>
                        <rect onClick={() => prop.func(1,0,"statue")} x="89.7" y="899.3" className="st3" width="90" height="90"/>
                </g>
            </g>

        </svg>
    );

export default FlanksZoomLinks;