import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import DownloadButton from '../../../../components/DownloadButton/DownloadButton';
import SingleSliderSwitch from '../../../../components/SingleSliderSwitch/SingleSliderSwitch';
import { gaButtonClick } from '../../../../utils/gatags';

import "../Introduction-fragment.css";
import "./FullBodyAnatomy.css"
import anatomyPDF from "../../../../assets/files/Art_of_anatomy_Skeleton_Muscle_Fat.pdf";

const FullBodyAnatomy = () => {

    const navigate = useNavigate();
    const [slider1value, setSlider1Value] = useState();
    const [slider2value, setSlider2Value] = useState();
    const sliderClick = (_idx, _state) => {
        // console.log( _idx, _state);
        if ( parseInt(_idx)===0 ) {
            setSlider1Value(_state);
        } 
        if ( parseInt(_idx)===1 ) {
            setSlider2Value(_state);
        }
    }
    const downloadPDF = () => {
        // window.location.href = anatomyPDF;
        //ADD TO OTHER LANGUAGES =====
        gaButtonClick("download-pdf","click", "Art_of_anatomy_Skeleton_Muscle_Fat.pdf")
        // ===
        window.open(anatomyPDF, '_blank');
    }

    return (
        <div id="full-body-anatomy" className="intro-anim-content">
            <p className="explore-number">01</p>
            <h1>Full Body Anatomy</h1>
            <div className="text100">
                <h2>PUTTING THE PIECES TOGETHER</h2>
            </div>
            <div className="text50-text50">
                <div className="width50">
                    <p>The human body is a complex anatomical construction, built on the foundational skeleton of 206 (or even up to 213) bones.<sup>22</sup> It contains layer upon layer of essential, specialised components, including muscle, organs, blood vessels, nerves, adipose tissue and connective tissue. These are the building blocks of a functioning human body, and each one significantly contributes to the body’s shape and form.<sup>22</sup></p>
                    <p>The below 3D model showcases the anterior, lateral and posterior views of the female body. You can examine the skeleton, muscles and fat pads together or by layer using the buttons on the right. You can also download this model as a poster for future reference.</p>
                </div>
                <div className="width50">
                    <p>As the size and shape of muscles and fat differ per person, it can be useful to refer to the surface landmarks (where the bone touches the skin).<sup>23</sup> For example, the anterior superior iliac spine, as a bony projection of the iliac bone, is naturally an important landmark of surface anatomy.<sup>23</sup></p>
                    <p>While this page offers a general overview, the rest of the human anatomy guide contains valuable information on each of the tissue layers to improve your understanding of the structure and composition of the body, and ultimately help you when contouring your patients’ bodies.</p>
                </div>
            
            </div>
            <div className="full-body-wrap">
                <div className="full-body-img-wrapper">
                    <div className="full-body-images">
                        
                        <div className="full-body-intro-img">
                            <img className="img-shadow" src={require("../../../../assets/images/full-body-skeleton@2x.png")} alt="Full body skeleton" />

                            <div className={slider2value ? "intro-img-overlay into-img intro-fat-layer" : "intro-img-overlay layer-hidden"}>
                                <img src={require("../../../../assets/images/full-body-fat@2x.png")} alt="Full body fat" />
                            </div>

                            <div className={slider1value ? "intro-img-overlay into-img intro-muscle-layer" : "intro-img-overlay layer-hidden"} >
                                <img src={require("../../../../assets/images/full-body-muscle@2x.png")} alt="Full body muscle" />
                            </div>

                        </div>
                    </div>
                    <div className="full-body-switches">
                        <h3>TURN ON AND OFF TO SEE DIFFERENT TISSUE LAYERS</h3>
                        <SingleSliderSwitch lbl="Muscles only" _idx="0" func={sliderClick} />
                        <SingleSliderSwitch lbl="Muscles and fat pads" _idx="1" func={sliderClick} />
                        {/* download link */}
                        {/* <div className='download-pdf'>
                            <img src={require("../../../../assets/images/download-btn@2x.png")} alt="Full body muscle" />
                            <h3>Download PDF</h3>
                        </div> */}
                        <DownloadButton label="DOWNLOAD PDF" downloadHandle={downloadPDF} />
                    </div>
                </div>
            </div>
            {/* <div className="text50-text50">
                <div className="width50">
                    <p>Download PDF</p>
                </div>
            </div> */}
        </div>
    );
};

export default FullBodyAnatomy;