import * as React from "react";
import "./EnlargeImgs.css";

    const AbdomenZoomLinks = (prop) => (
        <svg className="zoom-links"
            viewBox="0 0 1280 1552">

        <g id="lines">
            <path id="Path_936" className="st0" d="M851.2,677.4H883l141,141.2h32.2"/>
            <path id="Path_935" className="st0" d="M169.4,541.5h53l97.9,76.2H374"/>
            <path id="Path_935_00000053526995184013951370000015688766213734266499_" className="st0" d="M885.5,940.6h26.3l97.9,52.9h46.3"/>
            <path id="Path_935_00000149355974237831883770000000242581411886371766_" className="st0" d="M168.5,864.4h54l97.9,76.2h46.3"/>
        </g>
        <g id="hs2">
            <g id="Component_37_14" transform="translate(526.459 151.729)">
                <path id="Path_6-2" className="st1" d="M597.5,662.7c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                    S597.5,643.9,597.5,662.7L597.5,662.7z"/>
                <path id="Path_7-2" className="st1" d="M606,705.1l-18.4-18.4"/>
                <path id="Path_8-2" className="st1" d="M563.6,650v25.4"/>
                <path id="Path_9-2" className="st1" d="M550.9,662.7h25.4"/>
            </g>
            <rect onClick={() => prop.func(0,1,"popup") } x="1045" y="769.4" className="st2" width="90" height="90"/>
        </g>
        <g id="hs1">
            <g id="Component_37_13" transform="translate(1.5 1.5)">
                <path id="Path_6" className="st1" d="M166.1,539.2c0,18.7-15.2,33.9-33.9,33.9S98.3,558,98.3,539.2c0-18.7,15.2-33.9,33.9-33.9
                    S166.1,520.5,166.1,539.2L166.1,539.2z"/>
                <path id="Path_7" className="st1" d="M174.6,581.6l-18.4-18.4"/>
                <path id="Path_8" className="st1" d="M132.2,526.5v25.4"/>
                <path id="Path_9" className="st1" d="M119.5,539.2h25.4"/>
            </g>
            <rect onClick={() => prop.func(0,0,"popup") } x="88.7" y="495.7" className="st2" width="90" height="90"/>
        </g>
        <g id="statuelink1">
                {/* <rect id="statue2-hover" x="922" y="1334" className="statue-hover abd2-hover fade-transition" width="180" height="105"/> */}
                {/* <rect onClick={() => prop.func(0,1,"statue")} x="925" y="1301.3" className="audio-fill abdomen-statue1" width="190" height="150"/> */}
                <rect x="925" y="1301.3" className="audio-fill abdomen-statue1" width="190" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="682" y="1334" className="statue-hover abd1-hover fade-transition" width="240" height="105"/> */}
                {/* <rect onClick={() => prop.func(0,0,"statue")} x="660" y="1301.3" className="audio-fill abdomen-statue0" width="260" height="150"/> */}
                <rect x="660" y="1301.3" className="audio-fill abdomen-statue0" width="260" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(0,0,"audio")} x="1000.2" y="1137.5" className="audio-fill abdomen-statue" width="116.1" height="150"/> */}
                
        </g>




        <g id="info1">
            <g>
                <path className="st4" d="M168.5,864.4c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9S168.5,845.7,168.5,864.4z
                    M139.6,855.5l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6s0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2
                    c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7
                    c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9
                    l7.4-25.6H139.6z M135.8,840.5c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7s2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5
                    s1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5C138.1,839,136.8,839.5,135.8,840.5z"/>
                <rect onClick={() => prop.func(0,0,"statue")} x="89.6" y="819.4" className="st3" width="90" height="90"/>
            </g>
        </g>
        <g id="info2">
            <g>
                <path className="st4" d="M1123.9,992.8c0,18.7-15.2,33.9-33.9,33.9c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                    C1108.8,958.9,1123.9,974.1,1123.9,992.8z M1095,983.9l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6
                    c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3
                    c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3
                    c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H1095z M1091.2,968.9c-1,1-1.5,2.3-1.5,3.7s0.5,2.7,1.5,3.7
                    c1,1,2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7s-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5
                    C1093.5,967.4,1092.2,967.9,1091.2,968.9z"/>
                <rect onClick={() => prop.func(0,1,"statue")} x="1045" y="947.8" className="st3" width="90" height="90"/>
            </g>
        </g>
        
        </svg>
    );

export default AbdomenZoomLinks;