import React, {useState, useRef, useEffect} from 'react';
import { FaHeadphonesAlt } from 'react-icons/fa';
import { gaButtonClick } from '../../utils/gatags';

import EnlargeAbdomenMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeAbdomenMale';
import EnlargeAbdomenFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeAbdomenFemale';
import EnlargeFlanksMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeFlanksMale';
import EnlargeFlanksFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeFlanksFemale';
import EnlargeButtocksMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeButtocksMale';
import EnlargeButtocksFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeButtocksFemale';
import EnlargeThighMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeThighMale';
import EnlargeThighFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeThighFemale';
import EnlargeArmsMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeArmsMale';
import EnlargeArmsFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeArmsFemale';
import EnlargeSubmentalMale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeSubmentalMale';
import EnlargeSubmentalFemale from '../../pages/AnatomyTraining/EnlargeImgs/EnlargeSubmentalFemale';
import { useModalUpdate, useModalContentUpdate } from "../../components/context/modalContext";
import { useModalScrollUpdate } from "../../components/context/mixContext";
import ExploreAbdomen from '../../pages/AnatomyTraining/Explore/ExploreAbdomen';
import ExploreFlanks from '../../pages/AnatomyTraining/Explore/ExploreFlanks';
import ExploreButtocks from '../../pages/AnatomyTraining/Explore/ExploreButtocks';
import ExploreThigh from '../../pages/AnatomyTraining/Explore/ExploreThigh';
import ExploreArms from '../../pages/AnatomyTraining/Explore/ExploreArms';
import ExploreSubmental from '../../pages/AnatomyTraining/Explore/ExploreSubmental';

import AbdomenZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/AbdomenZoomLinks';
import FlanksZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/FlanksZoomLinks';
import ButtocksZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/ButtocksZoomLinks';
import ThighZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/ThighZoomLinks';
import ArmsZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/ArmsZoomLinks';
import SubmentalZoomLinks from '../../pages/AnatomyTraining/EnlargeImgs/SubmentalZoomLinks';

import Discobolus from '../../pages/AnatomyTraining/EnlargeImgs/Discobolus';
import VenusDeMilo from '../../pages/AnatomyTraining/EnlargeImgs/VenusDeMilo';
import TheThinker from '../../pages/AnatomyTraining/EnlargeImgs/TheThinker';
import TheNymphSalmacis from '../../pages/AnatomyTraining/EnlargeImgs/TheNymphSalmacis';
import Adonis from '../../pages/AnatomyTraining/EnlargeImgs/Adonis';
import VenusCallipyge from '../../pages/AnatomyTraining/EnlargeImgs/VenusCallipyge';
import VenusTyingHerSandal from '../../pages/AnatomyTraining/EnlargeImgs/VenusTyingHerSandal';
import MarcellusAsHermesLogios from '../../pages/AnatomyTraining/EnlargeImgs/MarcellusAsHermesLogios';
import NymphUntyingHerSandal from '../../pages/AnatomyTraining/EnlargeImgs/NymphUntyingHerSandal';
import PoseidonOfMelos from '../../pages/AnatomyTraining/EnlargeImgs/PoseidonOfMelos';
import AlexanderTheGreat from '../../pages/AnatomyTraining/EnlargeImgs/AlexanderTheGreat';
import VenusItalicaBust from '../../pages/AnatomyTraining/EnlargeImgs/VenusItalicaBust';

import "./GallerySlide.css";



const GallerySlide = (props) => {

  let popupContentLabels;
  let popupContentItems;
  let popupContentImgItems;
  let statueDescriptions;
  let popupContentGaLabels;
  let statueGaDescriptions;

  useEffect(() => {
    popupContentLabels = ["Abdomen","Flanks","Buttocks","Thigh","Arms","Submental"];
    popupContentItems = [ExploreAbdomen,ExploreFlanks,ExploreButtocks,ExploreThigh,ExploreArms,ExploreSubmental];
    popupContentImgItems = [[<EnlargeAbdomenFemale _muscle={muscle}/>,<EnlargeAbdomenMale _muscle={muscle}/>],[<EnlargeFlanksFemale _muscle={muscle}/>,<EnlargeFlanksMale _muscle={muscle}/>],[<EnlargeButtocksFemale _muscle={muscle}/>,<EnlargeButtocksMale _muscle={muscle}/>],[<EnlargeThighFemale _muscle={muscle}/>,<EnlargeThighMale _muscle={muscle}/>],[<EnlargeArmsFemale _muscle={muscle}/>,<EnlargeArmsMale _muscle={muscle}/>],[<EnlargeSubmentalFemale _muscle={muscle}/>,<EnlargeSubmentalMale _muscle={muscle}/>]]
    statueDescriptions = [[VenusDeMilo,Discobolus],[TheNymphSalmacis,TheThinker],[VenusCallipyge,Adonis],[VenusTyingHerSandal,MarcellusAsHermesLogios],[NymphUntyingHerSandal,PoseidonOfMelos],[VenusItalicaBust,AlexanderTheGreat]];
    popupContentGaLabels = [["AbdomenFemale","AbdomenMale"],["FlanksFemale","FlanksMale"],["ButtocksFemale","ButtocksMale"],["ThighFemale","ThighMale"],["ArmsFemale","ArmsMale"] ,["SubmentalFemale","SubmentalMale"]];
    statueGaDescriptions = [["VenusDeMilo","Discobolus"],["TheNymphSalmacis","TheThinker"],["VenusCallipyge","Adonis"],["VenusTyingHerSandal","MarcellusAsHermesLogios"],["NymphUntyingHerSandal","PoseidonOfMelos"],["VenusItalicaBust","AlexanderTheGreat"]];
    
    function handleResize() {
      // console.log("Handle resize");         
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
    
  })
  
  const hcpmodalSet = useModalUpdate(true);
  const extlinkSet = useModalContentUpdate(true);
  const modScrollSet = useModalScrollUpdate();
  const statueRef = useRef();
  const hotspotRef = useRef()
  const [muscle, setMuscle] = useState(true);
  
  const setModalContent = (_component, _scrollmode, _e, _index, _label) => {

    //ADD TO OTHER LANGUAGES =====
    if ( _e ) {
      gaButtonClick(popupContentLabels[_index] + '-button', "click", 'Explore'+ popupContentLabels[_index]);
    } else {
      gaButtonClick( _label + '-button', "click", 'Zoom'+ _label);
    }
    hcpmodalSet(true)
    extlinkSet(_component)
    modScrollSet(_scrollmode)
  }

  const zoomClick = (_sec, _idx, _type) => {
    if (_type==="popup") {
      setModalContent(popupContentImgItems[parseInt(props.indx)][_idx], false, false, _idx, popupContentGaLabels[props.indx][_idx]);
    }
    if (_type==="statue") {
      setModalContent(statueDescriptions[parseInt(props.indx)][_idx], false, false, _idx, statueGaDescriptions[props.indx][_idx]);
    }
    if (_type==="audio") {
      props.audiofunc(true);
    }
  }
  // ===
  
  
  const setLayers = (_mode) => {
    setMuscle(!muscle);
    gaButtonClick("show-fat", "click", `show-fat-for-${popupContentLabels[parseInt(props.indx)]}: ${muscle}`);
  }
  
  return (
    <>
    {/* <div slot="container-start" className="parallax-bg" data-swiper-parallax="0%"></div> */}
    <div id="slide1" className="slide-content" data-swiper-parallax="0">
      <div className="title text-glow" data-swiper-parallax="-4000"><h1>{props.title}</h1>
        <div className="button-wrap" data-swiper-parallax="-3000">
          <div className="explore-sound-btns">
            <div className="explore-now text-glow" onClick={(e) => setModalContent(popupContentItems[parseInt(props.indx)],true, true, props.indx)}>EXPLORE NOW</div>
            <div className="play-sound text-glow" onClick={(e) => zoomClick(parseInt(props.indx),0,"audio")}>
            
              <FaHeadphonesAlt className="text-glow" />

              <p className="text-glow">{parseInt(props.indx) < 10 ? '0' + (parseInt(props.indx)+1) : (parseInt(props.indx)+1) }</p>
            </div>
          </div>
            {/* <p className="instruction">Click on the icon below to show or hide the fat pads</p> */}
            <div className="button-group">
              <div className={ muscle ? "view-btn btnActive" : "view-btn btnInactive" } onClick={()=>setLayers(false)}>
                <div className={ muscle ? "fat-icon inactive" : "fat-icon active" }></div>
                <div className="label text-glow"><p>FAT PADS<br/><span>Click to show/hide</span></p></div>
              </div>
            </div>
            
        </div>



        {/* <div className="subTitle text-glow" data-swiper-parallax="-4000">
          <div className="secondary-head">
            <h2>EXPLORE TISSUES BY BODY AREA</h2>
          </div>
        </div> */}



        {/* <div className="secondary-head">
          <h2>Exploring tissues by body area</h2>
        </div> */}
      </div>
      {/* <div className="subTitle text-glow" data-swiper-parallax="-4000">
        <div className="secondary-head">
          <h2>Exploring tissues by body area</h2>
        </div>
      </div> */}
      <div id={"sculpture" + props.indx} ref={statueRef} className="sculpture-wrap">
        <div className={"sculpture sculpture" + props.indx}>
          <div className={ muscle ? "fat-layer" : "fat-layer show-fat" } ></div>

          <div className="hotspot-wrap" ref={hotspotRef}>
            <div className="hotspot hotspot1">
            {
              (()=> {
                switch (parseInt(props.indx)) {
                  case 0:
                    return <AbdomenZoomLinks func={zoomClick} />;
                    // break;
                  case 1: 
                    return <FlanksZoomLinks func={zoomClick} />;
                    // break;
                  case 2: 
                    return <ButtocksZoomLinks func={zoomClick} />;
                    // break;
                  case 3: 
                    return <ThighZoomLinks func={zoomClick} />;
                    // break;
                  case 4: 
                    return <ArmsZoomLinks func={zoomClick} />;
                    // break;
                  case 5: 
                    return <SubmentalZoomLinks func={zoomClick} />;
                    // break;
                  default:
                    // code block
                }

              })()
            }
            </div>
          </div>

        </div>
      </div>
      
      
    </div>
    
    </>
    );
  };
  
  
  export default GallerySlide;