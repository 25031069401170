import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const VenusItalicaBust = () => {

    gaModalContent(
        "anatomy-atatue-info-venus-italica-bust",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-venus-italica-bust@2x.png")} alt="Venus Italica bust" />
                </div>
                <div className="width60">
                    <p className="explore-number">Unknown artist, based on Antonio Canova</p>
                    <h2>Venus Italica Bust</h2>
                    <p>This beautiful marble was commissioned by Napoleon Bonaparte and fashioned by Italian sculptor, Antonio Canova. He finished the original work in 1802 and modelled two further variants, completed in 1819. The work was to be a replacement for the Venus de Medici, a copy of an antique sculpture by Cleomenes of Athens, which had been seized and taken to France on the orders of Bonaparte. After his abdication, the Venus de Medici was returned to Italy and is currently on display in Florence.</p>
                </div>
            </div>
        </>  
    );
};

export default VenusItalicaBust;