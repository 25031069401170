import React from 'react';
import { useNavigate } from "react-router-dom";
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import PlateButton from '../../components/PlateButton/PlateButton';
import { gaPageView, gaButtonClick } from '../../utils/gatags';

import "./ModuleSelection.css";
import anatomyIcon from "../../assets/images/anatomy-icon-grey.png"
import galleryIcon from "../../assets/images/gallery-icon-grey.png"
import JobCode from '../../components/JobCode/JobCode';

const ModuleSelection = () => {

    gaPageView(
        "module-selection",
        "pageview",
        window.location.pathname,
    )
    

    const navigate = useNavigate()
    const selectBtnClick = (_idx) => {
        if (parseInt(_idx)===0) {

             //ADD TO OTHER LANGUAGES =====
             gaButtonClick("module-select-button","click", "/anatomy-introduction/full-body-anatomy")
             // ===
            navigate("/anatomy-introduction/full-body-anatomy");
        } else {

            //ADD TO OTHER LANGUAGES =====
            gaButtonClick("module-select-button","click", "/anatomy-training")
            // ===
            navigate("/anatomy-training");
        }
    }

    return (
        <>
        <LeftMenu menuToggle={false} activeBtns={[true,false,false,false,false,false,true]} withFooter="btm30" logoutonly={false} />
            <section className="selection-section-wrap">

                <section className="selection-content">
                    <div className="selection-column-container">
                    <div className="selection-col width45">
                        <img onClick={() => selectBtnClick(0)} src={require("../../assets/images/anatomy-selector.png")} alt="Introduction to anatomy"/>
                        <PlateButton icon="false" iconpath="" plateImg={anatomyIcon} label="Introduction to human anatomy" btnhandle={selectBtnClick} btnindex="0" />
                    </div>
                    <div className="selection-col width45">
                        <img onClick={() => selectBtnClick(1)} src={require("../../assets/images/gallery-selector.png")} alt="Explore the gallery"/>
                        <PlateButton icon="false" iconpath="" plateImg={galleryIcon} label="Explore the gallery" btnhandle={selectBtnClick} btnindex="1"/>
                    </div>
                    </div>

                </section>

                {/* <div className="job-code"><p>UK-CSC-230171 | August 2023</p></div> */}
                <JobCode />

            </section>

        </>
    );
};

export default ModuleSelection;