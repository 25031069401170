import * as React from "react";
import "./EnlargeImgs.css";

    const ArmsZoomLinks = (prop) => (

        <svg className="zoom-links" x="0px" y="0px"
                viewBox="0 0 1280 1552">
            <g id="lines">
                <path id="Path_936" className="st0" d="M662.9,428.9h142.1L1024,648h32.2"/>
                <path id="Path_935" className="st0" d="M169.4,575.9h53l97.9,76.2h98.8"/>
                <path id="Path_935_00000120539124120911514980000015463430268612552369_" className="st0" d="M167.6,978.7h54.8l97.9-76.2h36.9"/>
                <path id="Path_935_00000162331381810118246040000010598361074925182353_" className="st0" d="M1056.1,978.7H1012L914,902.5h-36.9"/>
            </g>
            <g id="hs2">
                <g id="Component_37_14_00000134963553094319206510000015752628049280679053_" transform="translate(526.459 151.729)">
                    <path id="Path_6-2_00000029011464368846579580000002578153040141253512_" className="st1" d="M597.5,492.1c0,19.5-16.5,35.2-36.3,33.8
                        c-16.7-1.2-30.3-14.7-31.4-31.4c-1.4-19.8,14.3-36.3,33.8-36.3C582.3,458.2,597.5,473.3,597.5,492.1L597.5,492.1z"/>
                    <path id="Path_7-2_00000019680706443803547850000003423201906525943222_" className="st1" d="M606,534.5L587.5,516"/>
                    <path id="Path_8-2_00000167376448753905852450000006042774286583095708_" className="st1" d="M563.6,479.3v25.4"/>
                    <path id="Path_9-2_00000131358729824593311510000005144041396975552444_" className="st1" d="M550.9,492.1h25.4"/>
                </g>
                <rect onClick={() => prop.func(4,1,"popup")} x="1045" y="598.8" className="st2" width="90" height="90"/>
            </g>
            <g id="hs1">
                <g id="Component_37_13_00000068652030290365507970000005024242331635643062_" transform="translate(1.5 1.5)">
                    <path id="Path_6_00000016777802557782123870000012637987365884780959_" className="st1" d="M166.1,573.6c0,18.7-15.2,33.9-33.9,33.9
                        s-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9S166.1,554.8,166.1,573.6L166.1,573.6z"/>
                    <path id="Path_7_00000149353782422047614670000015761642951340583307_" className="st1" d="M174.6,616l-18.4-18.4"/>
                    <path id="Path_8_00000042725227013892807170000013944775275962357408_" className="st1" d="M132.2,560.8v25.4"/>
                    <path id="Path_9_00000142855026283034021920000012685898808779874702_" className="st1" d="M119.5,573.6h25.4"/>
                </g>
                <rect onClick={() => prop.func(4,0,"popup")} x="88.7" y="530.1" className="st2" width="90" height="90"/>
            </g>
            <g id="statuelink1">
                {/* <rect id="statue2-hover" x="832" y="1334" className="statue-hover abd2-hover fade-transition" width="270" height="105"/> */}
                {/* <rect onClick={() => prop.func(4,1,"statue")} x="830" y="1301.3" className="audio-fill arms-statue" width="286" height="150"/> */}
                <rect x="830" y="1301.3" className="audio-fill arms-statue" width="286" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="450" y="1334" className="statue-hover abd1-hover fade-transition" width="381" height="105"/> */}
                {/* <rect onClick={() => prop.func(4,0,"statue")} x="430" y="1301.3" className="audio-fill arms-statue" width="400" height="150"/> */}
                <rect x="430" y="1301.3" className="audio-fill arms-statue" width="400" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(4,0,"audio")} x="900.2" y="1137.5" className="audio-fill arms-statue" width="216.1" height="150"/> */}
            </g>


            <g id="info2">
                <g>
                    <path className="st4" d="M1123.9,978.7c0,18.7-15.2,33.9-33.9,33.9c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        C1108.8,944.8,1123.9,960,1123.9,978.7z M1095,969.8l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6
                        c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3
                        c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3
                        c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H1095z M1091.2,954.8c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
                        c1,1,2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5
                        C1093.5,953.2,1092.2,953.7,1091.2,954.8z"/>
                        <rect onClick={() => prop.func(4,1,"statue")} x="1045" y="933.7" className="st3" width="90" height="90"/>
                </g>
            </g>
            <g id="info1">
                <g>
                    <path className="st4" d="M167.6,978.7c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        S167.6,960,167.6,978.7z M138.7,969.8l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4
                        c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5
                        L139,994c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7
                        c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H138.7z M134.9,954.8c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5
                        c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7s-2.3-1.5-3.8-1.5C137.1,953.2,135.9,953.7,134.9,954.8z"/>
                        <rect onClick={() => prop.func(4,0,"statue")} x="88.7" y="933.7" className="st3" width="90" height="90"/>
                </g>
            </g>
        </svg>

    );

export default ArmsZoomLinks;