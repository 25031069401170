import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const VenusTyingHerSandal = () => {

    gaModalContent(
        "anatomy-atatue-info-venus-tying-her-sandal",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-venus-tying-her-sandal@2x.png")} alt="Venus tying her sandal" />
                </div>
                <div className="width60">
                    <p className="explore-number">Ettore Cadorin</p>
                    <h2>Venus tying her sandal</h2>
                    <p>Venus, goddess of love, and her son Cupid were favourite subjects in Greek and Roman sculpture. They appear in a variety of compositions, many of which were copied during the Renaissance and Baroque periods. The ability of this graceful, naturalistic figure to move freely in space is characteristic of the later phases of Greek art and showcases the advantages of bronze over marble.</p>
                </div>
            </div>
        </>  
    );
};

export default VenusTyingHerSandal;