import * as React from "react";
import "./EnlargeImgs.css";

    const ButtocksZoomLinks = (prop) => (

        <svg className="zoom-links" x="0px" y="0px"
                viewBox="0 0 1280 1552" >
            <g id="lines">
                <path id="Path_936" className="st0" d="M835.3,677.4H883l141,141.2h32.2"/>
                <path id="Path_935" className="st0" d="M169.4,631.5h53l97.9,76.2H374"/>
                <path id="Path_935_00000018199818538784647770000008173541219678800797_" className="st0" d="M1056,968.7h-53l-97.9-76.2h-79"/>
                <path id="Path_935_00000021812474091895093550000002753255138213439618_" className="st0" d="M167.7,968.7h53l97.9-76.2h92.6"/>
            </g>
            <g id="hs2">
                <g id="Component_37_14" transform="translate(526.459 151.729)">
                    <path id="Path_6-2" className="st1" d="M597.5,662.7c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        S597.5,643.9,597.5,662.7L597.5,662.7z"/>
                    <path id="Path_7-2" className="st1" d="M606,705.1l-18.4-18.4"/>
                    <path id="Path_8-2" className="st1" d="M563.6,650v25.4"/>
                    <path id="Path_9-2" className="st1" d="M550.9,662.7h25.4"/>
                </g>
                <rect onClick={() => prop.func(2,1,"popup")} x="1045" y="769.4" className="st2" width="90" height="90"/>
            </g>
            <g id="hs1">
                <g id="Component_37_13_00000178166897271778015970000018145620212270336139_" transform="translate(1.5 1.5)">
                    <path id="Path_6_00000008849113272524373250000015598167259500809373_" className="st1" d="M166.1,629.2c0,18.7-15.2,33.9-33.9,33.9
                        S98.3,648,98.3,629.2c0-18.7,15.2-33.9,33.9-33.9S166.1,610.5,166.1,629.2L166.1,629.2z"/>
                    <path id="Path_7_00000168095565716895790430000005431612507268355733_" className="st1" d="M174.6,671.6l-18.4-18.4"/>
                    <path id="Path_8_00000026135980851590337520000002294942604925562790_" className="st1" d="M132.2,616.5v25.4"/>
                    <path id="Path_9_00000060021836053972377290000010350470977156222364_" className="st1" d="M119.5,629.2h25.4"/>
                </g>
                <rect onClick={() => prop.func(2,0,"popup") } x="88.7" y="585.7" className="st2" width="90" height="90"/>
            </g>
            <g id="statuelink1">
                {/* <rect id="statue2-hover" x="901" y="1334" className="statue-hover abd2-hover fade-transition" width="200" height="105"/> */}
                {/* <rect onClick={() => prop.func(2,1,"statue")} x="900.2" y="1301.3" className="audio-fill buttocks-statue" width="216.1" height="150"/> */}
                <rect x="900.2" y="1301.3" className="audio-fill buttocks-statue" width="216.1" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="658" y="1334" className="statue-hover abd1-hover fade-transition" width="242" height="105"/> */}
                {/* <rect onClick={() => prop.func(2,0,"statue")} x="640" y="1301.3" className="audio-fill buttocks-statue" width="260" height="150"/> */}
                <rect x="640" y="1301.3" className="audio-fill buttocks-statue" width="260" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(2,0,"audio")} x="900.2" y="1137.5" className="audio-fill buttocks-statue" width="216.1" height="150"/> */}
            </g>


            <g id="info2">
                <g>
                    
                    <path className="st4" d="M1123.9,968.7c0,18.7-15.2,33.9-33.9,33.9c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        C1108.8,934.8,1123.9,949.9,1123.9,968.7z M1095,959.8l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6
                        c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3
                        c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3
                        c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H1095z M1091.2,944.7c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
                        c1,1,2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5
                        C1093.5,943.2,1092.2,943.7,1091.2,944.7z"/>
                        <rect onClick={() => prop.func(2,1,"statue")} x="1045" y="923.7" className="st3" width="90" height="90"/>
                </g>
            </g>
            <g id="info1">
                <g>
                    
                    <path className="st4" d="M167.6,968.7c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        S167.6,949.9,167.6,968.7z M138.7,959.8l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4
                        c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5
                        l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7
                        c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H138.7z M134.9,944.7c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7c1,1,2.3,1.5,3.7,1.5
                        c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7s-2.3-1.5-3.8-1.5C137.1,943.2,135.9,943.7,134.9,944.7z"/>
                        <rect onClick={() => prop.func(2,0,"statue")} x="88.7" y="923.7" className="st3" width="90" height="90"/>
                </g>
            </g>
        </svg>

    );

export default ButtocksZoomLinks;