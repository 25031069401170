import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// polyfill for IE11 for urlsearchparams
import 'url-search-params-polyfill';

import TagManager from 'react-gtm-module';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';

import "./assets/css/general.css";
import "./assets/fonts/fonts.css";

const tagManagerArgs = {
  gtmId: 'GTM-PL3MNW5',
  dataLayerName: 'PageDataLayer',
  events: {
    eventTracking: 'Event Tracking',
    pageView: 'Page View'
  }
}
TagManager.initialize(tagManagerArgs)

// console.log(document.querySelector("meta[name=viewport]"))

// document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));

ReactDOM.render(
  <BrowserRouter basename="/uk">
      <App />
  </BrowserRouter>,
  document.getElementById('root')
);