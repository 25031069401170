import * as React from "react";
import "./EnlargeImgs.css";

    const ThighZoomLinks = (prop) => (

        <svg className="zoom-links" x="0px" y="0px"
                viewBox="0 0 1280 1552">
            <g id="lines">
            <path id="Path_936" className="st0" d="M807.6,747.4H883l141,100.5h32.2"/>
            <path id="Path_935" className="st0" d="M169.4,579.4h53l97.9,76.2h65.8"/>
            <path id="Path_935_00000170968348514204808230000017275454573719920036_" className="st0" d="M167.6,992.1h54.8l97.9-76.2h65.8"/>
            <path id="Path_935_00000062896812322022428390000003467640112947669644_" className="st0" d="M1056.1,992.1h-37.9l-97.9-47.6h-80.9"/>
            </g>
            <g id="hs2">
                <g id="Component_37_14_00000002385647631070185910000012553231827470014082_" transform="translate(526.459 151.729)">
                    <path id="Path_6-2_00000127024645229807699550000015587998487253473466_" className="st1" d="M597.5,691.8c0,19.5-16.5,35.2-36.3,33.8
                        c-16.7-1.2-30.3-14.7-31.4-31.4c-1.4-19.8,14.3-36.3,33.8-36.3C582.3,657.9,597.5,673.1,597.5,691.8L597.5,691.8z"/>
                    <path id="Path_7-2_00000014617086386652729430000003964808273751805628_" className="st1" d="M606,734.2l-18.4-18.4"/>
                    <path id="Path_8-2_00000087401208937805009740000009699492120180901787_" className="st1" d="M563.6,679.1v25.4"/>
                    <path id="Path_9-2_00000050654564469166145790000007932437925195620489_" className="st1" d="M550.9,691.8h25.4"/>
                </g>
                <rect onClick={() => prop.func(3,1,"popup")} x="1045" y="798.6" className="st2" width="90" height="90"/>
            </g>
            <g id="hs1">
                <g id="Component_37_13_00000023261017831464912530000011889234988813724345_" transform="translate(1.5 1.5)">
                    <path id="Path_6_00000141440945596727263440000015068422705879937444_" className="st1" d="M166.1,577.1c0,18.7-15.2,33.9-33.9,33.9
                        s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9S166.1,558.4,166.1,577.1L166.1,577.1z"/>
                    <path id="Path_7_00000027578809308340751130000017412112941597864578_" className="st1" d="M174.6,619.5L156.2,601"/>
                    <path id="Path_8_00000022546774091521189070000007238047210441177243_" className="st1" d="M132.2,564.4v25.4"/>
                    <path id="Path_9_00000176754506101839420760000014571972665422529970_" className="st1" d="M119.5,577.1h25.4"/>
                </g>
                <rect onClick={() => prop.func(3,0,"popup")} x="88.7" y="533.6" className="st2" width="90" height="90"/>
            </g>
            <g id="statuelink1">
                {/* <rect id="statue2-hover" x="725" y="1334" className="statue-hover abd2-hover fade-transition" width="378" height="105"/> */}
                {/* <rect onClick={() => prop.func(3,1,"statue")} x="720" y="1301.3" className="audio-fill thigh-statue" width="396" height="150"/> */}
                <rect x="720" y="1301.3" className="audio-fill thigh-statue" width="396" height="150"/>
            </g>
            <g id="statuelink0">
                {/* <rect id="statue1-hover" x="398" y="1334" className="statue-hover abd1-hover fade-transition" width="325" height="105"/> */}
                {/* <rect onClick={() => prop.func(3,0,"statue")} x="380" y="1301.3" className="audio-fill thigh-statue" width="340" height="150"/> */}
                <rect x="380" y="1301.3" className="audio-fill thigh-statue" width="340" height="150"/>
            </g>
            <g id="audiolink">
                {/* <rect id="audio-hover" x="1035" y="1177" className="audio-hover fade-transition" width="68" height="82"/>
                <rect onClick={() => prop.func(3,0,"audio")} x="900.2" y="1137.5" className="audio-fill thigh-statue" width="216.1" height="150"/> */}
            </g>



            <g id="info2">
                <g>
                    <path className="st4" d="M1123.9,992.1c0,18.7-15.2,33.9-33.9,33.9c-18.7,0-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        C1108.8,958.2,1123.9,973.4,1123.9,992.1z M1095,983.2l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6
                        c0.5,0.4,0.7,0.9,0.7,1.4c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3
                        c4.1,0,7.9-3.2,11.6-9.5l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3
                        c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H1095z M1091.2,968.2c-1,1-1.5,2.3-1.5,3.7c0,1.5,0.5,2.7,1.5,3.7
                        c1,1,2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7c0-1.5-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5
                        C1093.5,966.6,1092.2,967.2,1091.2,968.2z"/>
                        <rect onClick={() => prop.func(3,1,"statue")} x="1045" y="947.1" className="st3" width="90" height="90"/>
                </g>
            </g>
            <g id="info1">
                <g>
                    <path className="st4" d="M167.6,991.6c0,18.7-15.2,33.9-33.9,33.9s-33.9-15.2-33.9-33.9c0-18.7,15.2-33.9,33.9-33.9
                        S167.6,972.8,167.6,991.6z M138.7,982.6l-12.2,2l-0.5,1.4c0.5,0,0.9,0,1.3,0c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.7,0.9,0.7,1.4
                        c0,0.8-0.2,2.1-0.7,3.7l-5,17.2c-0.4,1.4-0.6,2.5-0.6,3.4c0,1.3,0.5,2.5,1.4,3.3c0.9,0.9,2.2,1.3,3.7,1.3c4.1,0,7.9-3.2,11.6-9.5
                        l-1.2-0.7c-1.5,2.3-2.8,3.9-3.9,4.8c-0.4,0.4-0.8,0.5-1.2,0.5c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7
                        c0-0.5,0.2-1.4,0.6-2.9l7.4-25.6H138.7z M134.9,967.6c-1,1-1.5,2.3-1.5,3.7s0.5,2.7,1.5,3.7s2.3,1.5,3.7,1.5
                        c1.5,0,2.7-0.5,3.7-1.5c1-1,1.5-2.3,1.5-3.7s-0.5-2.7-1.5-3.7c-1-1-2.3-1.5-3.8-1.5C137.1,966.1,135.9,966.6,134.9,967.6z"/>
                        <rect onClick={() => prop.func(3,0,"statue")} x="88.7" y="946.6" className="st3" width="90" height="90"/>
                </g>
            </g>
        </svg>

    );

export default ThighZoomLinks;