import React from 'react';

import "../Introduction-fragment.css";
import "./Blood.css"

const Blood = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">11</p>
            <h1>Blood<sup>1</sup></h1>
            <div className="fw-text">
                <p>Blood is considered to be a connective tissue because it is made up of cells surrounded by a non-living fluid matrix (blood plasma) and has a fibrous component. However, this fibrous part is not immediately apparent. That is because the fibres of blood are soluble protein molecules that only become visible during blood clotting:</p>
            </div>
            <div className="text50-text50">
                <div className="width50">
                    <h2>Functions<sup>1</sup></h2>
                    <p>The main functions of connective tissue are:</p>
                    <ul>
                        <li>Fat storage</li>
                        <li>Binding of organs</li>
                        <li>Support of body structures</li>
                        <li>Physical protection</li>
                        <li>Immune protection</li>
                        <li>Movement</li>
                        <li>Heat production</li>
                        <li>Transport of gases, nutrients, wastes, hormones and blood cells</li>
                    </ul>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-blood-fig1@2x.png")} alt="introduction to blood"  />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blood;