import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const NymphUntyingHerSandal = () => {

    gaModalContent(
        "anatomy-atatue-info-nymph-untying-her-sandal",
        "modal-view",
        "/anatomy-training"
    );

    return (
        <>
            <div  id="statue" className="statue-desc explore-content">
                <div className="width40">
                    <img src={require("../../../assets/images/popstat-nymph-untying-her-sandal@2x.png")} alt="Nymph untying her sandal" />
                </div>
                <div className="width60">
                    <p className="explore-number">John Gibson</p>
                    <h2>Nymph untying her sandal</h2>
                    <p>This sculpture, also known as ‘Nymph at bath’ or simply ‘Nymph’, was fashioned by artist John Gibson (1790–1866). He described it as portraying “A nymph sitting, and whilst arranging her sandal, her attention is drawn off, which gives a momentary suspense to her occupation. Her form is slender and very youthful.” While aesthetically pleasing to many, the influential critic, Francis Turner Palgrave, was not impressed, listing it among works he considered emptily pretty when writing in the early 1860s.</p>
                </div>
            </div>
        </>  
    );
};

export default NymphUntyingHerSandal;