import React from 'react';

import "../Introduction-fragment.css";
import "./EpithelialTissue.css";

const EpithelialTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">03</p>
            <h1>Epithelial Tissue</h1>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Location<sup>1</sup></h2>
                    <p>Epithelial tissue, also known as epithelium: </p>
                    <ul>
                        <li>Covers exposed surfaces of the body</li>
                        <li>Lines body cavities and many organs</li>
                    </ul>
                    <p>Examples of where of epithelial tissue is found in the body:</p>
                    <ul>
                        <li>Skin</li>
                        <li>Lungs</li>
                        <li>Stomach</li>
                        <li>Small intestines</li>
                        <li>Kidneys</li>
                        <li>Blood vessels</li>
                        <li>Liver</li>
                        <li>Mouth</li>
                    </ul>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-epithelial-tissues-fig1@2x.png")} alt="introduction to epithelialtissues" />
                    </div>
                </div>
                
            </div>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Structure</h2>
                    <p>Epithelial tissue is formed from a sheet of closely adhering cells, one or more cells thick. The upper surface is usually exposed to the external environment or to an internal space within the body. </p>
                    <p className="mb40">Epithelium classes and cell shapes can be seen in the accompanying visuals.<sup>1</sup> </p>
                    <h2>Functions</h2>
                    <p>The main functions of epithelial tissue are<sup>1</sup>: </p>
                    <ul>
                        <li>Absorption of chemicals such as nutrients</li>
                        <li>Excretion of waste</li>
                        <li>Filtration of substances</li>
                        <li>Protection of deeper tissues from invasion and injury</li>
                        <li>Secretion of sweat and hormones</li>
                        <li>Sensation</li>
                    </ul>
                </div>
                <div className="width50">
                <div className="into-img">
                    <img className="img-shadow" src={require("../../../../assets/images/intro-epithelial-tissues-fig2@2x.png")} alt="introduction to epithelialtissues" />
                </div>
                </div>
            </div>
        </div>
    );
};

export default EpithelialTissue;