import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreSubmental = () => {

    gaModalContent(
        "anatomy-explore-submental",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">06</p>
                    <h2>Chin (submental and submandibular) area</h2>


                    <div className="text50-img50 mt0">
                        <div className="width50">
                        <p>The submental area is located directly under the chin, while submandibular literally means below the jaw.<sup>1,21</sup>  Four pairs of muscles produce the biting and chewing movements of the mandible (lower jaw), namely the <i>temporalis</i>, <i>masseter</i> and two pairs of <i>pterygoid</i> muscles:<sup>1</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                            <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig1@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                    </div>

                    <div className="text50-img50">
                        <div className="width50">
                        <p>A wide range of other muscles also support the jaw, neck and head:<sup>1</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                            <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig2@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                    </div>



                    <div className="text50-img50">
                        <div className="width50">
                            <p>There is adipose tissue under the chin called the pre-platysmal fat pad:<sup>21</sup></p>
                            <p>Hypertrophy (enlargement) of this fat pad can cause an increase in submental fullness, informally known as a ‘double chin’.<sup>21</sup> This enlarged fat pad is the underlying area being treated during cryolipoloysis of the submental area.<sup>21</sup></p>
                            <p>When undertaking submental cryolipoloysis, it is particularly important to be aware of:<sup>19</sup></p>
                            <ul>
                                <li>The hypoglossal nerve</li>
                                <li>The marginal mandibular nerve</li>
                                <li>The submandibular gland </li>
                            </ul>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig3@2x.png")} alt="Explore submental" />
                                <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig4@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                    </div>


                    <div className="text50-img50 mb20">
                        <div className="width50">
                            <p>The <b>hypoglossal nerve</b> enables tongue movement, helping people to undertake activities such as speaking and swallowing:<sup>1</sup></p>
                        </div>
                        <div className="width50">
                            <p>The <b>marginal mandibular nerve</b> innervates a range of muscles that work to provide facial expression and symmetry, while the <b>submandibular gland</b> produces saliva:<sup>1</sup></p>
                        </div>
                    </div>

                    <div className="text50-img50">
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig5@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig6@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                    </div>


                    <div className="text50-img50 lt-blue-wrapper">
                        <div className="width50">
                            <p>The CoolSculpting<sup>®</sup> user manual contains the following specific warnings:<sup>19</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img no-btm-mg">
                                <img className="img-shadow" src={require("../../../assets/images/explore-submental-fig7@2x.png")} alt="Explore submental" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreSubmental;