import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import PlateButton from '../../../../components/PlateButton/PlateButton';
import { gaButtonClick } from '../../../../utils/gatags';

import "../Introduction-fragment.css";
import "./ConnectiveTissue.css"

const ConnectiveTissue = (props) => {

    const navigate = useNavigate();
    const connectionhandle = (_loc) => {
        //ADD TO OTHER LANGUAGES =====
        gaButtonClick("connective-tissue-buttons","click",_loc)
        // ===

        navigate(_loc);
    }

    return (
        <div className="intro-anim-content">
            <p className="explore-number">06</p>
            <h1>Connective Tissue<sup>1</sup></h1>
            <div className="fw-text">
                <p>Connective tissue is the most abundant, diverse and widely distributed type of tissue in the body. All of these are types of connective tissue: </p>
                <div className="connective-link-wrap">
                    <div className="connectives">
                        <div className="conn-link width30">
                            <img src={require("../../../../assets/images/connectives-adipose.png")} alt="Adipose tissue" />
                            <PlateButton icon="false" iconpath="" label="Adipose tissue" btnhandle={(e) => connectionhandle("/anatomy-introduction/adipose-tissue")} btnindex="0"/>
                        </div>
                        <div className="conn-link width30">
                            <img src={require("../../../../assets/images/connectives-fibrose.png")} alt="Fibrose tissue" />
                            <PlateButton icon="false" iconpath="" label="Fibrous tissue" btnhandle={(e) => connectionhandle("/anatomy-introduction/fibrous-tissue")} btnindex="1"/>
                        </div>
                        <div className="conn-link width30">
                            <img src={require("../../../../assets/images/connectives-cartilage.png")} alt="Cartilage" />
                            <PlateButton icon="false" iconpath="" label="Cartilage" btnhandle={(e) => connectionhandle("/anatomy-introduction/cartilage")} btnindex="2"/>
                        </div>
                        <div className="conn-link width30">
                            <img src={require("../../../../assets/images/connectives-bone.png")} alt="Bone" />
                            <PlateButton icon="false" iconpath="" label="Bone" btnhandle={(e) => connectionhandle("/anatomy-introduction/bone")} btnindex="4"/>
                        </div>
                        <div className="conn-link width30">
                         <img src={require("../../../../assets/images/connectives-blood.png")} alt="Blood" />
                            <PlateButton icon="false" iconpath="" label="Blood" btnhandle={(e) => connectionhandle("/anatomy-introduction/blood")} btnindex="3"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectiveTissue;