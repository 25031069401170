import React from 'react';

import "../Introduction-fragment.css";
import "./MuscleTissue.css";

const MuscleTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">04</p>
            <h1>Muscle Tissue</h1>
            <div className="fw-text">
                <h2>Location<sup>1</sup></h2>
                <p>There are three types of muscle tissue: smooth, cardiac and skeletal. </p>
                <div className="width100">
                    <div className="musc-ill-wrap">
                        <div className="musc-ill">
                            <div className="musc-circle">
                                <h3>Smooth muscle</h3>
                                <p>Smooth muscle is found in areas such as the bladder, digestive tract and blood vessels</p> 
                            </div>
                            <div className="musc-circle">
                                <h3>Cardiac muscle</h3>
                                <p>Cardiac muscle is found in the heart</p>
                            </div>
                            <div className="musc-circle">
                                <h3>Skeletal muscle</h3>
                                <p>Skeletal muscle is found in areas associated with voluntary movement (e.g. arms, legs, back, chest, abdomen, hands, feet, neck, mouth and eyes)</p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Structure<sup>1</sup></h2>
                    <p>The structure of muscle tissue varies according to its cell type. Cardiac and skeletal muscle cells are striated, while smooth muscle cells are spindle-shaped: </p>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-muscle-tissue-fig1@2x.png")} alt="introduction to muscle tissue" />
                    </div>
                </div>
            </div>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Functions<sup>1</sup></h2>
                    <p>All muscles contract when stimulated, which exerts a physical force on other tissues, organs or fluids. As well as facilitating locomotion and other voluntary movements, this enables muscles to play an important role in:</p>
                    <ul>
                        <li>Blood circulation</li>
                        <li>Breathing</li>
                        <li>Digestion</li>
                        <li>Thermogenesis</li>
                        <li>Waste elimination</li>
                    </ul>
                </div>  
            </div>
        </div>
    );
};

export default MuscleTissue;