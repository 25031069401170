import React, { useContext } from 'react';

const piContext = React.createContext();
const piUpdateContext = React.createContext();



export function usePIs() {
   return useContext(piContext);
}

export function usePIUpdate() {
    return useContext(piUpdateContext)
}

export function PIProvider({ children }) {

    const [piOpen,setPIOpen] = React.useState(false);
    

    function setPI() {
        const piIsOpen = !piOpen
        setPIOpen(piIsOpen);
        console.log(piOpen);
    }
    
    return (

        <piContext.Provider value={piOpen}>
            <piUpdateContext.Provider value={setPI}>
                {children}
            </piUpdateContext.Provider>
        </piContext.Provider>

    )
}