import React from 'react';

import "../Introduction-fragment.css";
import "./AdiposeTissue.css";
import { gaModalContent } from '../../../../utils/gatags';

const AdiposeTissuePopup = () => {

        //ADD TO OTHER LANGUAGES =====
        gaModalContent(
            "cellulite",
            "modal-view",
            "/anatomy-introduction/adipose-tissue"
        );
           // ===


    return (
        <>
            <div id="general-popup" className="intro-anim-content general-popup">
                <h1>Cellulite</h1>
                <div className="popup-2col">
                    <div className="width50">
                        <h2>Definitions</h2>
                        <p>Cellulite is a topographic (relating to the physical features) and localised skin condition that is commonly found on the <b>outer thighs, buttocks, and abdomen</b>.<sup>1</sup> First described in 1978, cellulite is considered to be a sex-related difference in the structure of skin and subcutaneous tissue.<sup>1</sup> This aesthetic alteration of the skin surface is characterised by a padded ‘orange peel’ dimpling of the skin.<sup>2</sup></p>
                        <h2>How is cellulite formed</h2>
                        <p>Although the aetiology of cellulite remains unclear,<sup>2</sup> it is recognised that the perpendicular orientation of the <b>fibrous septa in women</b> allows fat to protrude, creating a rippled appearance. On the contrary, the oblique nature of these fibres in men appears to prevent the phenomenon.<sup>2</sup> These sex-related structural differences are confirmed in recent studies which provide further insight into the appearance of cellulite as a result of several physiological alterations, including enlarged fibrosclerotic septa that tether the skin in areas of cellulite and/or an uneven dermal-hypodermal interface.<sup>2</sup></p>
                        <p>Cellulite is estimated to occur in <b>80% to 98% of postpubertal women.</b><sup>2,3</sup> Although it is rare in healthy men, it can occur among those with sex hormone deficiency or requiring oestrogen therapy due to certain medical conditions.<sup>2</sup></p>
                    </div>
                    <div className="width50">
                        <div className="into-img">
                            <img className="img-shadow" src={require("../../../../assets/images/intro-cellulite-fig1@2x.png")} alt="introduction to cellulite" />
                        </div>
                        <p className="footnote footnote-right">Cellulite formation</p>
                        <h2>How is cellulite treated</h2>
                        <p>It has been difficult to achieve durable success with treatments for cellulite. Current treatments include topical agents, energy-based devices, subcision, injectable biologic agents and dermal fillers, all of which target key tissues (i.e., dermis, adipose, fibrous septa, or a combination of these).<sup>3</sup></p>
                    </div>
                </div>
                <div className='popup-ref'>
                    <p>References</p>
                    <ol>
                        <li>Luebberding S, <i>et al. Am J Clin Dermatol</i> 2015;16:243–256.</li>
                        <li>Bass LS, Kaminer MS. <i>Dermatol Surg</i> 2020;46:S77–S85.</li>
                        <li>Sadick N. <i>Int J Womens Dermatol</i> 2018;5:68–72.</li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default AdiposeTissuePopup;