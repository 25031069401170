import React from 'react';

import "../Introduction-fragment.css";
import "./FibrousTissue.css"

const FibrousTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">08</p>
            <h1>Fibrous Tissue</h1>
            <div className="fw-text">
                <p>Fibrous connective tissue is broadly divided into two categories: loose and dense.</p>
                <p>In <b>loose connective tissue</b>, much of the space is occupied by ‘ground substance’. Ground substance usually has a gelatinous to rubbery consistency and absorbs compressive forces, protecting more delicate cells from mechanical injury.<sup>1</sup></p>
            </div>

            <div className="lt-blue-wrapper-1col">
                <div className="full-width-heading">
                        <h3>EXAMPLES OF LOOSE CONNECTIVE TISSUE</h3>
                    </div>
                <div className="fragment-wrapper-2col">
                    <div className="width50">
                        <div className="into-img mb20">
                            <img className="img-shadow" src={require("../../../../assets/images/intro-fibrous-tissue-fig1@2x.png")} alt="introduction to fibrous distribution" />
                        </div>
                        <h3>Areolar<sup>1</sup></h3>
                        <ul>
                            <li>Loosely binds epithelia to deeper tissues</li>
                            <li>Enables passage of nerves and blood vessels through other tissues</li>
                            <li>Provides an arena for immune defence</li>
                            <li>Houses blood vessels to deliver nutrients and remove waste from overlying epithelia</li>
                        </ul>
                    </div>
                    <div className="width50">
                        <div className="into-img mb20">
                            <img className="img-shadow" src={require("../../../../assets/images/intro-fibrous-tissue-fig2@2x.png")} alt="introduction to fibrous distribution" />
                        </div>
                        <h3>Reticular<sup>1</sup></h3>
                        <ul>
                            <li>Has a loose network of reticular fibres and cells, infiltrated with numerous white blood cells</li>
                            <li>Found in areas such as lymph nodes and bone marrow</li>
                            <li>Provides a supportive framework for lymphatic organs</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fw-text">
                <p><b>In dense <span className='underline-txt'>regular</span> connective tissue,</b> collagen fibres are closely packed and run parallel to each other. This parallel arrangement is well suited to tendons and ligaments, given that the stresses placed on them are in predictable directions.<sup>1</sup></p>
                <p><b>Dense <span className='underline-txt'>irregular</span> connective tissue</b> also has thick bundles of collagen, but these bundles run in seemingly random directions, enabling the tissue to resist unpredictable stresses. This type of tissue makes up most of the dermis, for example, where it binds the skin to underlying muscle and other tissues.<sup>1</sup></p>
            </div>
            <div className="lt-blue-wrapper-1col">
                <div className="full-width-heading">
                    <h3>Examples of dense connective tissues</h3>
                </div>
                <div className="fragment-wrapper-2col">
                    <div className="width50">
                        <div className="into-img mb20">
                            <img className="img-shadow" src={require("../../../../assets/images/intro-fibrous-tissue-fig3@2x.png")} alt="introduction to fibrous distribution" />
                        </div>
                        <h3>Dense regular<sup>1</sup></h3>
                        <ul>
                            <li>Contains densely packed, parallel, often wavy collagen fibres</li>
                            <li>Located in areas such as tendons and ligaments</li>
                            <li>Parallel arrangement of fibres is suited to stresses in predictable directions</li>
                        </ul>
                    </div>
                    <div className="width50">
                        <div className="into-img mb20">
                            <img className="img-shadow" src={require("../../../../assets/images/intro-fibrous-tissue-fig4@2x.png")} alt="introduction to fibrous distribution" />
                        </div>
                        <h3>Dense irregular<sup>1</sup></h3>
                        <ul>
                            <li>Contains densely packed collagen fibres, running in random directions</li>
                            <li>Located in areas such as the dermis of skin</li>
                            <li>Withstands stresses applied in  unpredictable directions, giving tissues durability</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FibrousTissue;