import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import "./Explore.css"

const ExploreThigh = () => {

    gaModalContent(
        "anatomy-explore-Thigh",
        "modal-view",
        "/anatomy-training"
    );

    return (

        <>

            <div id="explore-content" className="explore-content">

                <div id="tab1" className="tab-content tab-content-active">
                    <p className="explore-number">05</p>
                    <h2>Back of arms<sup>1</sup></h2>
                    <div className="text50-img50 mt0">
                        <div className="width50">
                            <p>The <i>triceps brachii</i> is the only muscle in the posterior (rear) compartment of the upper arm. It runs down the back of the humerus (upper arm bone) and ends at the top of the ulna (forearm bone).</p> 
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-arms-fig1@2x.png")} alt="Explore arms" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50">
                        <div className="width50">
                            <p>A cross-sectional view also shows that the muscles of the arm are surrounded by a layer of subcutaneous fat:</p>
                            <p>It is important to note that a range of nerves, including the ulnar nerve, run the whole length of the arm.</p>
                            <p>Looking at the arm in cross-section shows that the <i>triceps brachii</i> has three muscle heads or points of origin (hence the prefix ‘tri’ in triceps).</p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-arms-fig2@2x.png")} alt="Explore arms" />
                            </div>
                        </div>
                    </div>
                    <div className="text50-img50 lt-blue-wrapper">
                        <div className="width50">
                            <p>The CoolSculpting® user manual has a specific warning to avoid compression of the ulnar nerve when treating the upper arm:<sup>19</sup></p>
                        </div>
                        <div className="width50">
                            <div className="img">
                                <img className="img-shadow" src={require("../../../assets/images/explore-arms-fig3@2x.png")} alt="Explore arms" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </>

    );
};


export default ExploreThigh;