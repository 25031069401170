import React from 'react';
import { gaModalContent } from '../../../utils/gatags';

import './EnlargeImgs.css';

const EnlargeThighFemale = ({_muscle})  => {

    gaModalContent(
        "anatomy-atatue-info-thigh-female",
        "modal-view",
        "/anatomy-training"
    );
    
    return (
        <>
            <div id="enlarge" className="img-holder">
                {_muscle ? 
                    <img src={require("../../../assets/images/popup-thigh-female.png")} alt="Thigh female" /> :
                    <img src={require("../../../assets/images/popup-thigh-female-fat.png")} alt="Thigh female" />
                }
            </div>
        </>
    );
};

export default EnlargeThighFemale;