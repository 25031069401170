import './JobCode.css';

const JobCode = () => {
    return (
        <div className="job-code-comp">
        <div className='job-code'>
            <p>Promotional website produced and funded by Allergan Aesthetics, an AbbVie company.</p>
            <p>UK-CSC-230171 | August 2023</p>
        </div>
    </div>
    )
}

export default JobCode