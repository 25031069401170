import React from 'react';
import { gaPageView } from '../../utils/gatags';

const Page404 = () => {

    gaPageView(
        "error-404",
        "pageview",
        window.location.pathname,
    )

    return (
        <>
        <section className="section-wrap">
            <section className="width1024">
                    <p>Page not found.</p>
            </section>
        </section>
        </>
    );
};


export default Page404;