import React from 'react';
import { useNavigate } from "react-router-dom";
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import PlateButton from '../../components/PlateButton/PlateButton';
import { gaPageView, gaButtonClick } from '../../utils/gatags';

import "./Home.css";
import JobCode from '../../components/JobCode/JobCode';

const Home = () => {
    const navigate = useNavigate();
    const btnhandle = () => {
        //ADD TO OTHER LANGUAGES =====
        gaButtonClick("home-enter-button","click",window.location.pathname)
        // ===
        navigate("/module-selection");
    }

    gaPageView(
        "homepage",
        "pageview",
        window.location.pathname,
    )

    return (
        <>
            <section className="home-section-wrap">

                <LeftMenu menuToggle={false} activeBtns={[false,false,false,false,false,false,true]} withFooter="btm30" logoutonly={true} />
                
                <section className="home-content">
                    <div className="home-right-col width60 glow">
                        <div className="home-text">
                            <h1>Welcome to the Art<br/>of Body Contouring</h1>
                            <div className="small-rule"></div>
                            <p>This interactive training resource is guided by a simple principle: The greater your knowledge of human anatomy and the physiology of what lies beneath the fat, the deeper your insight in developing treatment plans and optimising body contouring for your patients.</p>
                            <p>Whether you’re an experienced body contouring practitioner looking to refresh your knowledge, or a newly qualified medical aesthetician aspiring to better understand the human body, the aim of The Art of Body Contouring is to help you become even more confident.</p>
                            {/* <p>This digital training module is divided into two sections:</p> */}
                            <div className="welcome-list">
                                <div className="list-item">
                                    <div className="icon anatomy"></div>
                                    <div className="welcome-list-text">
                                        <p>Dive into the <span className="emph">human anatomy guide</span> and discover just what makes the human body such a complex and magical system.</p>
                                    </div>
                                </div>
                                <div className="list-item">
                                    <div className="icon gallery"></div>
                                    <div className="welcome-list-text">
                                    <p>Take a tour of our <span className="emph">virtual sculpture gallery</span>, where you can peel back the layers and marvel at what lies beneath, soaking up expert knowledge from a renowned body contouring expert (by simply clicking the headphone icons).</p>
                                    </div>
                                </div>
                            </div>
                            <p>Step inside and enjoy the journey!</p>
                            {/* <p>After the training you should be able to:</p>
                            <ul>
                                <li>Design sufficient treatment plans based on a deep understanding of body anatomy</li>
                                <li>Understand and become familiarised about the art of body contouring</li>
                            </ul> */}
                        </div>

                        
                        
                        <div className="home-enter-wrap">
                            <PlateButton icon="true" iconpath="gallery-icon.png" label="Enter" btnhandle={btnhandle} btnindex="1"/>
                        </div>
                    </div>
                </section>

                {/* <div className="job-code"><p>UK-CSC-230171 | August 2023</p></div> */}
                <JobCode />

            </section>

        </>
    );
};

export default Home;