import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useModal, useModalUpdate, useModalContent }  from "../context/modalContext";
import { useModalScroll, useModalScrollUpdate }  from "../context/mixContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ModalTabs from '../../pages/AnatomyTraining/Explore/ModalTabs';

import './PopupModal.css'

const PopupModal = () => {


    const modalState = useModal(true);
    const setModalState = useModalUpdate();
    const modalContent = useModalContent();
    const scrollMode = useModalScroll();

    Modal.setAppElement('#root')

    const customStyles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 40,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 1000
        },
        content : {
          position: 'relative',
          background: '#FFFFFF',
          inset: 0,
          margin: 20,
          overflow: 'unset',
          border: "none",
          borderTopRightRadius: 4
        }
      };

      function openModal(){
        setModalState(true);
      }

      function closeModal(){
        setModalState(false);
      }

      function afterOpenModal(){
        

        // required to differentiate between scrolling content and an image popup
        // console.log( React.Children.count(modalContent.props.children) )
        if ( React.Children.count(modalContent.props.children) > 0 ) {
          if ( modalContent.props.children.props.id === "explore-content" ) {
            const modalScroll = document.querySelector('#react-modal');
            modalScroll.classList.add('scroll-class');
            const modalContentScroll = document.querySelector('#modal-content');
            modalContentScroll.classList.add('modal-content-scroll-class');
          }
          if ( modalContent.props.children.props.id === "general-popup" ) {
            const modalScroll = document.querySelector('#react-modal');
            modalScroll.classList.add('scroll-class');
            modalScroll.classList.add('general-popup-dims');
            const modalContentScroll = document.querySelector('#modal-content');
            modalContentScroll.classList.add('modal-content-scroll-class');
          }
          if ( modalContent.props.children.props.id === "enlarge" ) {
            const modalScroll = document.querySelector('#react-modal');
            modalScroll.classList.add('scroll-class');
            modalScroll.classList.add('general-popup-dims');
            const modalContentScroll = document.querySelector('#modal-content');
          }
          if ( modalContent.props.children.props.id === "statue" ) {
            const modalScroll = document.querySelector('#react-modal');
            modalScroll.classList.add('scroll-class');
            modalScroll.classList.add('general-popup-dims');
            const modalContentScroll = document.querySelector('#modal-content');
            modalContentScroll.classList.add('modal-content-scroll-statue-class');
          }
        }
      }

      function afterClose(){
        // 
      }

    return (
      <Modal
            closeTimeoutMS={0}
            isOpen={ modalState }
            onAfterOpen={afterOpenModal}
            onAfterClose={afterClose}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            id="react-modal"
            >
              <div className="modal-header-transparent">
              <div className="close-btn-transparent" onClick={closeModal}><FontAwesomeIcon icon={faTimes} /></div>
              </div>
              <div id="modal-content" className="modal-content">
                {modalContent}
              </div>
              
        </Modal>
    );
};


export default PopupModal;