import React from "react";
import { gaPageView } from '../../utils/gatags';
 
import Gallery from "../../components/Gallery/Gallery";

import "./AnatomyTraining.css";


const AnatomyTraining = () => {

  gaPageView(
    "anatomy-training",
    "pageview",
    window.location.pathname,
  )


  const swipePagList = document.querySelectorAll(".swiper-pagination-bullet");

  return (
    <>
      <section className="section-wrap">
        <Gallery />
      </section>
    </>
  );

};

export default AnatomyTraining;
