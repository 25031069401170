import React, { useState, useEffect } from 'react';
import './SingleSliderSwitch.css';

const SingleSliderSwitch = (props) => {

    const [sliderState, setSliderState] = useState(false);

    const singleSliderClicker = (_idx) => {
        setSliderState(!sliderState)
        props.func(props._idx, !sliderState);
    }

    useEffect(() => {
        props.func(0, sliderState);
    },[]);
    

    return (
        <div>
            <div className="single-slider-wrap" onClick={ (e) => singleSliderClicker(0) }>
                <div className="single-slider-track">
                    <div id={props._id ?  props._id : " sslb0"} className={sliderState ? "single-slider-slider-btn single-slb-active" : "single-slider-slider-btn"} ></div>
                </div>
                <div className="single-slider-label"><p>{props.lbl}</p></div>
            </div>
        </div>
    );
};

export default SingleSliderSwitch;