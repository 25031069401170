import React from 'react';

import "../Introduction-fragment.css";
import "./NervousTissue.css"

const NervousTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">05</p>
            <h1>Nervous Tissue</h1>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Location<sup>1</sup></h2>
                    <p>Nervous tissue is the key component of the nervous system, which has central and peripheral parts and covers all of the body:</p>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-nervous-tissue-fig1@2x.png")} alt="introduction to nervous tissue" />
                    </div>
                </div>
                
            </div>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Structure<sup>1</sup></h2>
                    <p>Nervous tissue is composed of nerve cells (neurons) and neuroglia:  </p>
                    <ul>
                        <li>Neurons receive and transmit nerve impulses</li>
                        <li>Neuroglia provide mechanical and structural support to neurons</li>
                    </ul>
                </div>
                <div className="width50">
                    <div className="into-img">
                        <img className="img-shadow" src={require("../../../../assets/images/intro-nervous-tissue-fig2@2x.png")} alt="introduction to nervous tissue" />
                    </div>
                </div>
            </div>
            <div className="text50-img50">
                <div className="width50">
                    <h2>Functions<sup>1</sup></h2>
                    <p>The nervous tissue communicates electrical and chemical signals throughout the body, regulating and controlling a huge range of functions, from breathing, heart rate and digestion to learning, memory and movement.</p>
                </div>  
            </div>
        </div>
    );
};

export default NervousTissue;