import React from 'react';

import "../Introduction-fragment.css";
import "./IntroductionTissue.css"

const IntroductionTissue = () => {
    return (
        <div className="intro-anim-content">
            <p className="explore-number">02</p>
            <h1>Introduction to tissues</h1>
            <div className="fw-text">
                <p>The word ‘tissue’ is used to describe a group of cells that have a similar structure and perform a specific function.<sup>1</sup> There are four main types of tissue:<sup>1</sup></p>
            </div>
            <div className="into-img">
                <img className="img-shadow" src={require("../../../../assets/images/intro-introduction-to-tissues-fig1@2x.png")} alt="introduction to tissues" />
            </div>
            <div className="fw-text">
                <p>Let us take a closer look at each of these, examining their location, structure and functions.</p>
            </div>
        </div>
    );
};

export default IntroductionTissue;