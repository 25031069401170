import React, {useState} from 'react';

import "../Introduction-fragment.css";
import "./FatDistribution.css"

const FatDistribution = () => {


    const [inProp1, setInProp1] = useState(true);
    const [inProp2, setInProp2] = useState(false);


   const handleClick = (e, _btn) => {

        if (_btn === "btn0") {
            setInProp2(false);
            const element = document.getElementById("expand1");
            if (inProp2) {
                console.log("Two is open");
                /** Remove the `active` class when the animation ends. */
                element.addEventListener('transitionend', () => {
                    setInProp1(true);
                }, {once: true})
            } else {
                console.log("Two is closed");
                setInProp1(!inProp1);
            }
            
            
        } else if (_btn === "btn1") {
            setInProp1(false);
            const element = document.getElementById("expand0");
            if (inProp1) {
                console.log("One is open");
                /** Remove the `active` class when the animation ends. */
                element.addEventListener('transitionend', () => {
                    setInProp2(true);
                }, {once: true})
            } else {
                console.log("One is closed");
                setInProp2(!inProp2);
            }
            
        }

    }

    return (
        <div className="intro-anim-content">
            <p className="explore-number">14</p>
            <h1>Fat Distribution</h1>
            <div className="text50-text50">
                <div className="width50">
                    <p>Adipose tissue is mainly found under the skin or surrounding internal organs, although it can be found in other sites too.<sup>8</sup> These additional sites include bone marrow, the retro-orbital and periarticular regions – and inside tissues (e.g. muscles, organs).<sup>8,11</sup></p>
                    <p>In all, adipose tissue makes up 10–20% of a man’s body and 20–30% of a woman’s body.<sup>2</sup></p>
                </div>
                <div className="width50">
                    <ul>
                        <li>Positioning of fat is the same in both men and women<sup>8</sup>
                            <ul>
                                <li>Located mainly either beneath the skin (subcutaneous adipose tissue) or around internal organs (visceral adipose tissue)</li>
                            </ul>
                        </li>
                        <li>Accumulation of adipose tissue is generally located in different places in men and women<sup>7,8,12</sup>
                            <ul>
                                <li>Men, on average, are more likely to develop android obesity due to the tendency of accumulating abdominal visceral fat</li>
                                <li>In pre-menopause women, predominant subcutaneous fat accumulation in the hip and thigh area is the most common</li>
                            </ul>
                        </li>
                    </ul> 
                </div>
            </div>

            <div className="img50-text50 fat__dist-wrap">
                {/* {(inProp1 || inProp2)  && 
                <div className="width50">
                    <div id="fat-dist" className="img">
                        <div className={inProp1 ? "switch-image switch-active" : "switch-image"}>
                            <div className="into-img">
                                <img src={require("../../../../assets/images/intro-fat-distribution-fig1@2x.png")} alt="introduction to fat distribution" />
                            </div>
                        </div>
                        <div className={inProp2 ? "switch-image switch-active" : "switch-image"}>
                            <div className="into-img">
                                <img src={require("../../../../assets/images/intro-fat-distribution-fig1@2x.png")} alt="introduction to fat distribution" />
                            </div>
                        </div>
                    </div>
                </div>
                
                } */}


                <div className="width50">
                    <div id="fat-dist" className="img">
                        <div className="switch-image switch-active">
                            <div className="into-img">
                                <img src={require("../../../../assets/images/intro-fat-distribution-fig1@2x.png")} alt="introduction to fat distribution" />
                            </div>
                        </div>
                    </div>
                </div>



                <div className="width50">
                    <div className="fat9dist">
                        <div id="slide0" onClick={(e) => handleClick(e, "btn0")} className="fat-dist-head">
                            <p>Subcutaneous adipose tissue</p>
                            <div className={inProp1 ? "drop-indicator rot-arrow" : "drop-indicator"}></div>
                        </div>
                    </div>
                    <div id="expand0" className={inProp1 ? "fdistexpand-content expand" : "fdistexpand-content minimise"}>
                        <div className="fdistexpand-text">
                        <p>Around 80–90% of adipose tissue is subcutaneous, found under the skin.<sup>8</sup> The main depots of subcutaneous adipose tissue are in the abdomen, upper back, buttocks and thighs.<sup>8</sup></p>
                        <p>Body contouring procedures (e.g. cryolipolysis) and fat reduction treatment are used commonly in body parts like abdomen, flanks, hips and thighs.<sup>13</sup></p>
                        </div>
                    </div>
        
                    <div className="fat9dist">
                        <div id="slide1" onClick={(e) => handleClick(e, "btn1")} className="fat-dist-head">
                            <p>Visceral adipose tissue</p>
                            <div className={inProp2 ? "drop-indicator rot-arrow" : "drop-indicator"}></div>
                        </div>
                    </div>

                    <div id="expand1" className={inProp2 ? "fdistexpand-content expand" : "fdistexpand-content minimise"}>
                        <div className="fdistexpand-text">
                        <p>Fat deposits located around internal organs such as the liver and intestines are known as visceral adipose tissue.<sup>8</sup></p>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default FatDistribution;