import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useRefUpdate } from "../context/refContext";
import { usePIUpdate } from "../context/piContext";
import { gaButtonClick } from '../../utils/gatags';

import "./LeftMenu.css"

const LeftMenu = ( props ) => {

    const setRefState = useRefUpdate();
    const setPIState = usePIUpdate();
    const navigate = useNavigate();

    const _path = window.location.pathname;

    const homeClass = _path === "/" ? "btn-active" : "";
    // const anatomyClass = _path.match(/^\/full-body-anatomy/) ? "btn-active" : "";
    const anatomyClass = _path.match(/^\/anatomy-introduction/) ? "btn-active" : "";
    const trainingClass = _path.match(/^\/anatomy-training/) ? "btn-active" : "";
    const logoutOnly = props.logoutonly ? "logout-only" : "";


    const lMenClick = (_num) => {
        if (_num === 0) {
            navigate("/module-selection");
            gaButtonClick("home-button", "click", "/module-selection");
        }
        if (_num === 1) {
            navigate("/anatomy-introduction/full-body-anatomy");
            gaButtonClick("full-body-anatomy-button", "click", "/home");
        }
        if (_num === 2) {
            navigate("/anatomy-training");
            gaButtonClick("anatomy-training-button", "click", "/anatomy-introduction/full-body-anatomy");
        }
        if (_num === 3) {
            props.menuToggle();
            gaButtonClick("menu-toggle", "click", "toggle-menu");
        }
        if (_num === 4) {
            setRefState(true);
            gaButtonClick("references-button", "click", "open-references");
        }
        if (_num === 5) {
            setPIState(true);
            gaButtonClick("PI-button", "click", "open-pi");
        }
        if (_num === 6) {
            console.log("logout");
            gaButtonClick("logout-button", "click", "log-out");
            window.parent.postMessage('logout', 'https://www.artofbodycontouring.com/');
        }
    }

    return (
        <div className={"menu-wrap " + props.withFooter + " trans-bg"}>
            <div className={props.activeBtns[0] ? `menu-button bb home-icon ${homeClass}` : "inactive"} onClick={() => lMenClick(0)}></div>
            <div className={props.activeBtns[1] ? `menu-button bb anatomy-icon ${anatomyClass}` : "inactive"} onClick={() => lMenClick(1)}></div>
            <div className={props.activeBtns[2] ? `menu-button bb gallery-icon mg-btm ${trainingClass}` : "inactive"} onClick={() => lMenClick(2)}></div>
            <div className={props.activeBtns[3] ? "menu-button menu-icon" : "menu-button menu-button-inactive" } onClick={() => lMenClick(3)}></div>
            {/* <div className={props.activeBtns[3] ? "menu-button bbt menu-icon" : "menu-button menu-button-inactive bbt" } onClick={() => lMenClick(3)}></div> */}
            <div className={props.activeBtns[4] ? "menu-button bt bl references-icon mrg-top" : "inactive"} onClick={() => lMenClick(4)}></div>
            <div className={props.activeBtns[5] ? "menu-button bt bl ae-icon" : "inactive"} onClick={() => lMenClick(5)}></div>
            <div className={props.activeBtns[6] ? `menu-button bt logout-icon ${logoutOnly}` : "inactive"} onClick={() => lMenClick(6)}></div>
        </div>
    );
};

export default LeftMenu;